import { Typography } from "@mui/material";
import React from "react";

const Text = ({
    color = "textPrimary",
    fontWeight = 400,
    fontSize = 14,
    className,
    children,
    margin,
    textTransform,
    singleLine = false,
    ...props
}) => {
    return (
        <Typography
            margin={margin}
            fontSize={`${fontSize}px !important`}
            fontWeight={`${fontWeight} !important`}
            color={color}
            sx={{
                ...(singleLine
                    ? {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "block",
                      }
                    : {}),
            }}
            {...props}
        >
            {children}
        </Typography>
    );
};

Text.propTypes = {};

export default Text;
