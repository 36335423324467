import * as actions from "../actions/types";

const initialState = {
  addBankAutoLogin: [],
};

const addBankAutoLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ADD_BANK_AUTO_LOGIN:
      return {
        ...state,
        addBankAutoLogin: action.data,
      };
    case actions.ADD_BANK_AUTO_LOGIN_SUCCESS:
      return {
        ...state,
        addBankAutoLogin: action.data,
      };
      case actions.ADD_BANK_AUTO_LOGIN_ERROR:
        return {
          ...state,
          addBankAutoLogin: action.data,
        };
    default: {
      return state;
    }
  }
};
export default addBankAutoLoginReducer;
