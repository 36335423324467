export const LOGIN = "login_old";
export const REGISTER_PAGE = "register_old";

export const SIGN_UP = "register";
export const SIGNUP_EMAIL = "signup_email";
export const SIGNUP_MOBILE = "signup_mobile";
export const SIGNUP_MOBILEOTP = "signup_mobile_otp";
export const CONSENT_GST = "consent";
export const SIGNUP_CONGRATS = "signup_success";

export const SIGN_IN = "login";
export const VERIFY_CUSTOMER_EMAIL = "verifyCustomerEmail";

export const FORGETPASSWORD = "forget_password";
export const FORGETPASSWORDOTP = "forget_password_otp";
export const FORGETPASSWORDFIELD = "new_password";

export const CRIF_DETAILS = "crif";

export const CONTACT_CREATE_LEAD = "create_lead";

export const BULK_SMS_SEND = "bulk_sms_send";
export const BULK_SMS_SEND_INDIVIDUAL = "sms_send_to_customer";

export const CONTACT_API = "contact_api";

export const RepaymentHistory = "repaymentHistory";
export const HOME_PAGE = "/";
export const DASHBOARD = "dashboard";
export const TASK = "task";
export const TASKLISTPAGE = "taskpage";
export const SUCCESS = "success";
export const USERS = "users";
export const Clients = "clients";
export const USER_PROFILE = "users/profile/:userId";
// export const PIPELINEDETAILS = "pipeline/details/:pipelineId";
export const PIPELINEDETAILS = "pipeline/details";
export const CHAT_AI = `business_detail/:bname/:bid/chat`;
export const ADDTASK = "addtask";
export const ALLPIPELINEDETAILSPAGE = "allpipelinedetailspage";
export const ALLPIPELINEPUSHEDPAGE = "allpipelinepushedspage";
export const ADDPIPELINEFORM = "addpipeline";
export const MobileOTPVerification = "mobile/otp";
export const ADDUSERMODALFORM = "addusermodalform";
export const EDITTASK = "edittask";
export const TASKDETAILS = "taskdetails";
export const SETTINGS = "settings";
export const FORGOTPASSWORD = "forgot_password";
export const CREATEPASSWORD = "password/create";
export const PIPELINES = "pipelines";
export const APPLICATIONSTAGE = "applicationstage";
export const AddleadSourceModel = "addleadsource";
// export const LEADSDASHBOARD = "pipeline/:pipeline_id/leads";
export const LEADSDASHBOARD = "pipeline/leads";
export const LEADS = "leadsPage";
export const CONTACTS = "contacts";
export const CUSTOMER_DETAILS = "customer_details";
export const SSILS = "settings/userdetails";
export const NODATAFOUND = "nodatafound";
export const APPLICATION = "application";
export const APPLICATIONDETAILS = "applicationdetails";
export const VERIFYEMAIL = "verify/email";
export const USERPROFILE = "profile";
export const PROFILEDETAILS = "profile/details";
export const USERS_LOGIN_PAGE = "users/createpassword";
export const FORMCREATOR = "pipeline/form_creator";
export const PARTNERS = "partners/";
export const BRANCH = "branches/";
export const PARTNER_DETAILS_PAGE = `/partners/:id`;
export const BRANCH_DETAILS_PAGE = `/branches/details`;
export const PARTNER_PIPELINE_DETAILS_PAGE = `/partners/:id/:partnerName/pipeline/:pipelineName/:pipelineId/sourcelink`;
export const PARTNER_LEAD_DASHBOARD =
    // `/partner/pipeline/leads?pipelineId=:pipelineID&pipeline=:pipeline`
    "partners/pipeline/leads";

export const LMSYSTEM = "lmsystem";
export const LOSYSTEM = "losystem";
export const TYPE_ONE = "companylist";
export const LEADSSETTINGSFORM = "leadpullcriteria";

export const LEADSDETAILS = "leadsdetails_old";
export const LEADSDETAILSNEW = "leadsdetails";
export const LEADDETAILS = "/leaddetails";
export const CAMREPORT = "/camReport/:id";
export const BIRREPORT = "/BIReport/:id";
export const CUSTOMERDETAILS = "/customerDetails";

export const CUSTOMERDETAILSPAGE = "customer_details_page_old";
export const CUSTOMER_NEW_DETAILS_PAGE = "customer_details_page";

export const BUSINESSRULES = "business_rules";
export const BUSINESSRULESDETAILS = "business_rules_details";
export const BUSINESSRULESEDITDETAILS = "business_rules_edit_details/:id";
export const WORKFLOWLIST = "wok_flow_List";
export const WORKFLOWDETAILS = "wok_flow_Details";
export const BANKLISTPAGE = "bank_details_page";

export const BUSINESSLIST = "business_list";
export const BUSINESSDETAILS = "businessDetails";

export const PARTNERSTABLE = "partners_table";
export const SOURCENAMETABLE = "source_table";
export const PipelineSettings = "pipeline_settings";

export const MARKETPLACE = "apps";

export const REPORTS = "reports";
export const GENERATE_SANCTION_LETTER = "generateSanctionLetter";
export const GENERATE_AGREEMENT = "generateLoanOfferAgreement";
export const CREDITMEMO = "credit_account_memorandam_report";
