import * as actions from "../actions/types";

const initialState = {
  autoLoginListData: [],
};

const autoLoginListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.AUTO_LOGIN_LIST:
      return {
        ...state,
        autoLoginListData: action.payload,
      };
    case actions.AUTO_LOGIN_LIST_SUCCESS:
      return {
        ...state,
        autoLoginListData: action.data,
      };
    case actions.AUTO_LOGIN_LIST_ERROR:
      return {
        ...state,
        autoLoginListData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default autoLoginListReducer;
