import * as actions from "../actions/types";

const initialState = {
  usersListData: [],
  usersListPartnerData: [],
};

const userslistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.USERS_LIST_ACTION:
      // return state;
      return {
        ...state,
        usersListData: action.data,
      };
    case actions.USERS_LIST_ACTION_SUCCESS:
      return {
        ...state,
        usersListData: action.data,
      };
    case actions.USERS_LIST_PARTNER_ACTION:
      return state;

    case actions.USERS_LIST_PARTNER_SUCCESS:
      return {
        ...state,
        usersListPartnerData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default userslistReducer;
