import * as actions from "../actions/types";

const initialState = {
  pipelineLeadsListData: [],
};

const pipelineLeadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.PIPELINE_LEADS:
        return {
            ...state,
            // pipelineLeadsListData: [],
        };
    case actions.PIPELINE_LEADS_SUCCESS:
        return {
            ...state,
            pipelineLeadsListData: action.data,
        };
    case actions.PIPELINE_LEADS_FAILED:
        return {
            ...state,
            pipelineLeadsListData: [],
        };
    default: {
        return {
            ...state,
            pipelineLeadsListData: [],
        };
    }
  }
};
export default pipelineLeadsReducer;
