import * as actions from "../actions/types";

const initialState = {
  bankCredentialList: [],
};

const allBankCredentialListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ALL_BANK_CREDENTIAL_LIST:
      return {
        ...state,
        bankCredentialList: action.data,
      };
    case actions.ALL_BANK_CREDENTIAL_LIST_SUCCESS:
      return {
        ...state,
        bankCredentialList: action.data,
      };
      case actions.ALL_BANK_CREDENTIAL_LIST_ERROR:
      return {
        ...state,
        bankCredentialList: action.data,
      };
    default: {
      return state;
    }
  }
};
export default allBankCredentialListReducer;
