import {
    Backdrop,
    Button,
    CircularProgress,
    Fade,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { roleList, roleListSuccess } from "../../../store/actions/roleList";
import { userList, userListSuccess } from "../../../store/actions/userslist";

import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllRoles } from "../../../api/services/Permissions";
import { addMember } from "../../../store/actions/addMember";
import { updateAlertMsg } from "../../../store/actions/commonAction";
import { updateMember } from "../../../store/actions/updateMember";
import { getCookie } from "../../../utils/common";
import useStyles from "../Users.style";
// import { ColourOption, colourOptions } from '../data';

const AddUserModal = (props) => {
    // console.log("oooooooooooop", props);
    const classes = useStyles();
    console.log("props=>", props);
    const { modalopen, handleClose, isPartner = false } = props;
    const [message, setMessage] = useState("");
    const { userDetails: editUserDetails = {}, roleLists, modalOpenFlag } = props;
    const { roleListData = {} } = roleLists;
    const { data: roleListArray = [] } = roleListData;
    const [selectedOption, setSelectedOption] = useState([]);
    const [roleArray, setRoleArray] = useState([]);

    console.log({ roleArray });
    // const { roleLists = [] } = props;
    // const { roleListData = {} } = roleLists;
    // const { data: roleListArray = [] } = roleListData;
    // const { data: roleListArray = [] } = props;
    const [userArr, setUserArr] = useState([]);
    const { UserLists = {}, statusLists = [], priorityLists } = props;
    const { response = [], data = [] } = UserLists;
    const [roleId, setroleId] = useState({});
    const [assignRoleId, setAssignRole] = useState({});
    const [reportToId, SetReportToId] = useState([]);
    // console.log(props, roleListArray, editUserDetails);
    const userId = getCookie("user_id");
    const name = getCookie("name");
    const [checked, setChecked] = React.useState(false);
    const [userDetailsObj, setUserDetailsObj] = useState({});
    // const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = React.useState(false);

    // CSS Styles Starts
    const selectStyle = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? "bold" : "normal",
            fontSize: state.selectProps.myFontSize,
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: state.data.color,
            fontSize: state.selectProps.myFontSize,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            fontSize: state.selectProps.myFontSize,
        }),
    };

    const fetchAllRoles = () => {
        getAllRoles().then((response) => {
            if (response) {
                if (response.data) {
                    setRoleArray(
                        response.data.roleList.map((each) => ({
                            label: each.name,
                            value: each.id,
                        }))
                    );
                }
            }
        });
    };

    ////// set user list for repoting to array ///////
    useEffect(() => {
        console.log("user data=>", props.teamMembers);
        let arr = [];
        let obj = { value: "", label: "" };
        props.teamMembers &&
            props.teamMembers.length > 0 &&
            props.teamMembers.map((item) => {
                return (obj.value = item.id), (obj.label = item.fullName), arr.push({ ...obj }), setUserArr(arr);
            });
    }, [props.teamMembers]);

    ////// set role array //////
    useEffect(() => {
        fetchAllRoles();
    }, []);

    ///// set by default value in reporting to value /////
    useEffect(() => {
        if (props.forAddUser) {
            console.log("inside add user");
        } else {
            console.log("inside edit USer", editUserDetails);
            let arr = [];
            let obj = { value: "", label: "" };
            editUserDetails &&
                editUserDetails.reporting &&
                editUserDetails.reporting.length > 0 &&
                editUserDetails.reporting.map((item) => {
                    return (
                        (obj.value = item?.reporting?.id),
                        (obj.label = item?.reporting?.fullName),
                        // console.log(obj),
                        arr.push({ ...obj }),
                        setSelectedOption(arr)
                    );
                });
            let newObj = { value: "", label: "" };
            if (editUserDetails && editUserDetails.Role && editUserDetails.Role) {
                newObj = {
                    value: editUserDetails.Role.id,
                    label: editUserDetails.Role.name,
                };
                console.log("Role=>", obj);
                setroleId(newObj);
                setChecked(editUserDetails.is_active);
                setAssignRole(editUserDetails.Role.id);
            } else {
                setroleId(roleArray[0]);
            }
        }
    }, [editUserDetails]);

    ////////////////////set default value in role /////////////////////
    // useEffect(() => {
    //   let obj = { value: "", label: "" };
    //   if (editUserDetails && editUserDetails.role && editUserDetails.role) {
    //     obj = {
    //       value: editUserDetails.role.id,
    //       label: editUserDetails.role.name,
    //     };
    //     setroleId(obj);
    //     setChecked(editUserDetails.is_active);
    //     setAssignRole(editUserDetails.role.id);
    //   } else {
    //     setroleId(roleArray[0]);
    //   }
    // }, [editUserDetails]);
    //////////////////////////////////////////

    const handleChangeReporting = (selectedOption) => {
        setSelectedOption([...selectedOption]);
        // console.log(`Option selected:`, selectedOption);
    };

    const handleChangeRole = (e) => {
        setAssignRole(e && e.value & e.value);
        console.log(`Option selected:`, e);
    };

    /////////// add and update user API function/////////
    console.log("for Add USer");
    const handleSubmit = (values) => {
        console.log("values on submit", values, selectedOption, assignRoleId);
        // setLoading(true);
        ////// set bydefault value in dropdown ///////
        var reportingPersonId = "";
        let arr = [];
        selectedOption &&
            selectedOption.length > 0 &&
            selectedOption.map((reportToId) => {
                // console.log(reportToId, "jhkjhkhjkjhjhkk");
                return (
                    (reportingPersonId = reportToId.value), arr.push(reportingPersonId)
                    // console.log(arr)
                    // SetReportToId(arr)
                );
            });

        // console.log(roleId, arr);
        let status = checked === true ? 1 : 0;
        let roledata = {
            // ...values,
            ///////// need to discuss for type//////

            reporting_to: arr && arr.length > 0 && arr,
            // reporting_to: arr && arr.length > 0 ? arr : userId,

            // role_id: roleId.value,
            role_id: assignRoleId,
            phone: values && values.phone ? values.phone : editUserDetails.phone,
            email: values && values.email ? values.email : editUserDetails.email,
            fullName: values && values.fullName ? values.fullName : editUserDetails.fullName,
            is_active: status,
        };
        // setUserDetailsObj(roledata);
        let id = editUserDetails.id;
        let userUpdatedata = {
            type: "employee",
            // reporting_to: arr && arr.length > 0 ? arr : userId,
            reporting_to: arr && arr.length > 0 && arr,
            role_id: assignRoleId,
            phone: values && values.phone ? values.phone : editUserDetails.phone,
            email: values && values.email ? values.email : editUserDetails.email,
            fullName: values && values.fullName ? values.fullName : editUserDetails.fullName,
            is_active: status,
        };
        let updateData = { userUpdatedata, id };
        ////////////////////
        console.log("updateData", updateData, roledata);
        return new Promise((resolve, reject) => {
            setLoading(true);
            if (editUserDetails && editUserDetails.email) {
                props.updateMember(updateData, resolve, reject);
                console.log("for edit");
            } else {
                props.addMember(roledata, resolve, reject);
                console.log("for add");
            }
        })
            .then((data) => {
                setLoading(false);
                handleClose();
                props.getUsersData(true);

                props.updateAlertMsg({
                    message: (data && data.message) || "",
                    messageType: "success",
                });
                // props.userList();
                //sushil Commented
            })
            .catch((err) => {
                setLoading(false);
                console.log("err", err.message);
                const { response } = err;
                const { data } = response;
                const { message } = data;
                props.updateAlertMsg({
                    message: (message && message) || "Something went wrong. Please try again!",
                    messageType: "error",
                });
            });
    };

    const handleChangeStatus = (event) => {
        // console.log(event.target.checked);

        setChecked(event.target.checked);
    };

    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={modalopen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            {/* <Box> */}
            <Slide direction="down" in={modalopen} timeout={200}>
                <Fade in={modalopen}>
                    <div className={classes.paper}>
                        <Grid className={classes.fradGrid}>
                            <div>
                                <Typography
                                    id="transition-modal-title"
                                    className={classes.headerStyle}
                                    style={{ fontFamily: "Red Hat Display", fontWeight: 600 }}
                                >
                                    {editUserDetails && editUserDetails.fullName
                                        ? "Update User Details"
                                        : "Add New User"}
                                </Typography>
                            </div>
                            <div className={classes.closeIconStyle}>
                                <CloseIcon onClick={handleClose} />
                            </div>
                        </Grid>

                        {/* <Grid className={classes.detailsText}>
              <Typography variant="caption" className={classes.memberDetails}>
                Member Details
              </Typography>
            </Grid> */}
                        <Formik
                            initialValues={{
                                fullName: editUserDetails ? editUserDetails.fullName : "",
                                email: editUserDetails ? editUserDetails.email : "",
                                role_id: editUserDetails ? editUserDetails.role_id : null,
                                reporting_to: selectedOption ? selectedOption : null,
                                phone: editUserDetails ? editUserDetails.phone : "",
                                //  null,
                            }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, { setErrors, resetForm, errors }) => {
                                // switch()
                                console.log(
                                    "values",
                                    assignRoleId,
                                    JSON.stringify(assignRoleId) === JSON.stringify({})
                                );
                                let error_type = { ...errors };
                                if (
                                    !values.fullName ||
                                    !values.email ||
                                    !values.phone ||
                                    selectedOption.length === 0 ||
                                    JSON.stringify(assignRoleId) === JSON.stringify({})
                                ) {
                                    console.log("inside if something empty");
                                    if (!values.fullName) {
                                        error_type.fullName = "Full name is required";
                                    }
                                    if (!values.email) {
                                        error_type.email = "Email is required";
                                    }
                                    if (!values.phone) {
                                        console.log("values.phone=>", values.phone);
                                        error_type.phone = "Phone no is required";
                                    }
                                    if (selectedOption.length === 0) {
                                        error_type.reporting_to = "Reporting to is required";
                                    }
                                    if (JSON.stringify(assignRoleId) === JSON.stringify({})) {
                                        console.log("inside equal");
                                        error_type.role_id = "Role is required";
                                    }
                                    if (values.email && !values.email.match(reg)) {
                                        error_type.email = "Invalid email";
                                    }
                                    if (values.phone && values.phone.length !== 10) {
                                        error_type.phone = "Phone no should be 10 digits long!";
                                    }
                                } else {
                                    console.log("if not all empty");
                                    if (!values.email.match(reg) || values.phone.length !== 10) {
                                        if (!values.email.match(reg)) {
                                            error_type.email = "Invalid email";
                                        }
                                        if (values.phone.length !== 10) {
                                            error_type.phone = "Phone no should be 10 digits long!";
                                        }
                                    } else {
                                        handleSubmit(values);
                                    }
                                    // setErrors(error_type);
                                }
                                setErrors(error_type);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setValues,
                                isSubmitting,
                            }) => (
                                <>
                                    <Grid style={{ padingTop: "39px 4px 2px 4px" }}>
                                        <Grid container className={classes.loginFormContainer} spacing={4}>
                                            <Grid item xs={12} sm={6} style={{ padding: "5px 16px" }}>
                                                {/* <InputLabel className={classes.inputLabelStyle}>
                          Member Name
                        </InputLabel> */}
                                                <label
                                                    style={{
                                                        fontSize: 16,
                                                        marginBottom: 5,
                                                        fontFamily: "Red Hat Display",
                                                        fontWeight: 500,
                                                    }}
                                                    htmlFor="fullName"
                                                >
                                                    Full Name
                                                </label>
                                                <TextField
                                                    id="fullName"
                                                    name="fullName"
                                                    defaultValue={
                                                        values && values.fullName
                                                            ? values.fullName
                                                            : editUserDetails && editUserDetails.fullName
                                                    }
                                                    placeholder=" Enter user name"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    style={{
                                                        fontFamily: "Red Hat Display",
                                                        fontWeight: 500,
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            root: classes.bootstrapRoot,
                                                            input: classes.bootstrapInput,
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false,
                                                        className: classes.bootstrapFormLabel,
                                                    }}
                                                />
                                                {errors.fullName && (
                                                    <FormHelperText error>{errors.fullName}</FormHelperText>
                                                )}
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                style={{
                                                    padding: "5px 16px",
                                                    fontFamily: "Red Hat Display",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                <InputLabel
                                                    className={classes.inputLabelStyle}
                                                    style={{
                                                        fontFamily: "Red Hat Display",
                                                        fontWeight: 500,
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    Email Id
                                                </InputLabel>
                                                {/* <label
                          style={{ fontSize: 16, marginBottom: 5 }}
                          htmlFor="email"
                        >
                          Enter Email Id
                        </label> */}
                                                <TextField
                                                    id="email"
                                                    name="email"
                                                    defaultValue={
                                                        values && values.email
                                                            ? values.email
                                                            : editUserDetails && editUserDetails.email
                                                    }
                                                    fullWidth
                                                    disabled={editUserDetails && editUserDetails.email ? true : false}
                                                    placeholder=" Enter email id"
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            root: classes.bootstrapRoot,
                                                            input: classes.bootstrapInput,
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false,
                                                        className: classes.bootstrapFormLabel,
                                                    }}
                                                />
                                                {errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
                                            </Grid>
                                            {/* <Grid item xs={10} sm={6}>
                        <InputLabel className={classes.inputLabelStyle}>
                          Employee Id
                        </InputLabel>
                        <TextField
                          id="employee_Id"
                          name="employee_Id"
                          placeholder="Enter employe id"
                          onChange={handleChange}
                          defaultValue={values.employee_Id}
                          error={errors.employee_Id}
                          helperText={errors.employee_Id}
                          fullWidth
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              root: classes.bootstrapRoot,
                              input: classes.bootstrapInput,
                            },
                          }}
                          InputLabelProps={{
                            shrink: false,
                            className: classes.bootstrapFormLabel,
                          }}
                        />
                      </Grid> */}

                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                style={{
                                                    padding: "5px 16px",
                                                    fontFamily: "Red Hat Display",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {/* <InputLabel
                          style={{ paddingBottom: "6px" }}
                          className={classes.inputLabelStyle}>
                          Reporting To
                        </InputLabel> */}
                                                <label style={{ fontSize: 16, marginBottom: 5 }} htmlFor="reporting_to">
                                                    Reporting To
                                                </label>
                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.respReportingStyle}
                                                    // className={classes.bootstrapInput}
                                                >
                                                    <Select
                                                        id="reporting_to"
                                                        name="reporting_to"
                                                        defaultValue={
                                                            selectedOption && selectedOption
                                                            // : [{ value: userId, label: name }]
                                                        }
                                                        onChange={handleChangeReporting}
                                                        options={userArr && userArr}
                                                        isMulti
                                                        className="select"
                                                        placeholder="Select reporting authority"
                                                        myFontSize="16px"
                                                        styles={selectStyle}
                                                        menuPosition="fixed"
                                                        // components={{ ControlComponent }}
                                                    />
                                                    {/* {errors.reporting_to && (
                            <FormHelperText error>
                              {errors.reporting_to}
                            </FormHelperText>
                          )} */}
                                                    {/* <Select
                            id="reporting_to"
                            name="reporting_to"
                            onChange={handleChangeReporting}
                            value={
                              values && values.reporting_to
                                ? values.reporting_to
                                : editUserDetails &&
                                  editUserDetails.reporting_to
                                ? editUserDetails.reporting_to
                                : userId && userId
                            }
                            // onChange={(e) => {
                              console.log(e);
                            //   setValues({
                            //     ...values,
                            //     reporting_to: e.target.value,
                            //   });
                            // }}
                            className={classes.selectInputHeight}>
                            {userArr &&
                              userArr.length > 0 &&
                              userArr.map((item) => {
                                return (
                                  <MenuItem value={item.id}>
                                    <em>{item.name}</em>
                                  </MenuItem>
                                );
                              })}
                          </Select> */}
                                                </FormControl>
                                                {errors.reporting_to && (
                                                    <FormHelperText error>{errors.reporting_to}</FormHelperText>
                                                )}
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                style={{
                                                    padding: "5px 16px",
                                                    fontFamily: "Red Hat Display",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {/* <InputLabel className={classes.inputLabelStyle}>
                          Mobile No.
                        </InputLabel> */}
                                                <label style={{ fontSize: 16, marginBottom: 5 }} htmlFor="phone">
                                                    Mobile No.
                                                </label>
                                                <TextField
                                                    id="phone"
                                                    name="phone"
                                                    placeholder=" Enter mobile number"
                                                    onChange={handleChange}
                                                    defaultValue={
                                                        values && values.phone
                                                            ? values.phone
                                                            : editUserDetails && editUserDetails.phone
                                                    }
                                                    fullWidth
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        type: "phoneNumber",
                                                        classes: {
                                                            root: classes.bootstrapRoot,
                                                            input: classes.bootstrapInput,
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false,
                                                        className: classes.bootstrapFormLabel,
                                                    }}
                                                />
                                                {errors.phone && <FormHelperText error>{errors.phone}</FormHelperText>}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                style={{
                                                    padding: "5px 16px",
                                                    fontFamily: "Red Hat Display",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {/* <InputLabel
                          style={{ paddingBottom: "6px" }}
                          className={classes.inputLabelStyle}
                        >
                          Role
                        </InputLabel> */}
                                                <label style={{ fontSize: 16, marginBottom: 5 }} htmlFor="role_id">
                                                    Role
                                                </label>
                                                <FormControl variant="outlined" className={classes.respReportingStyle}>
                                                    <Select
                                                        id="role_id"
                                                        className="select"
                                                        name="role_id"
                                                        defaultValue={roleId && roleId}
                                                        onChange={handleChangeRole}
                                                        placeholder="Select role"
                                                        options={roleArray}
                                                        myFontSize="16px"
                                                        styles={selectStyle}
                                                        menuPosition="fixed"
                                                    />
                                                </FormControl>
                                                {errors.role_id && (
                                                    <FormHelperText error>{errors.role_id}</FormHelperText>
                                                )}
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                style={{
                                                    padding: "5px 16px",
                                                    fontFamily: "Red Hat Display",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {/* <InputLabel
                          style={{
                            marginTop: 10,
                            color: "#172B4D",
                            fontWeight: 400,
                            fontSize: 14,
                          }}
                        >
                          Select Status
                        </InputLabel> */}

                                                {/* <Grid
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                            marginLeft: "-7px",
                          }}> */}
                                                {/* <div>
                            <Switch
                              // checked={checked}
                              // onChange={handleChangeStatus}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </div> */}
                                                {/* <div>
                            <InputLabel
                              className={classes.inputLabelStyle}
                              style={{ marginTop: 11 }}>
                              {checked == true ? "Active" : "Inactive"}
                            </InputLabel>
                          </div> */}
                                                {/* </Grid> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid className={classes.buttonStyle}>
                                        <Grid style={{ margin: "4px 1px 1px 5px" }}>
                                            <Button
                                                variant="outlined"
                                                /* className={classes.adduserButtonstyle} */
                                                onClick={handleClose}
                                                style={{
                                                    color: "#0a2472",
                                                    border: "1px solid #0a2472",
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid style={{ margin: "4px 5px 3px 4px" }}>
                                            <Button
                                                variant="contained"
                                                disabled={loading}
                                                style={{ width: "100%" }}
                                                // loading={loading}
                                                onClick={() => handleSubmit()}
                                                className={classes.adduserButtonstyle}
                                            >
                                                {loading && <CircularProgress size={20} />}
                                                {/* {!loading && "Create member"} */}

                                                {!loading && editUserDetails && editUserDetails.fullName
                                                    ? "Update user"
                                                    : "Add user"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Formik>
                    </div>
                </Fade>
            </Slide>
            {/* </Box> */}
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    roleLists: state.roleList,
    UserLists: state.userList.usersListData,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateMember,
            roleListSuccess,
            roleList,
            addMember,
            userList,
            userListSuccess,
            updateAlertMsg,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
