import * as actions from "../actions/types";

const initialState = {
  generalData: [],
};

const gkDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GENERAL_DATA_ACTION:
      return {
        ...state,
        generalData: action.data,
      };
    case actions.GENERAL_DATA_ACTION_SUCCESS:
      return {
        ...state,
        generalData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default gkDataReducer;
