import * as actions from "../actions/types";

const initialState = {
  organisationData: {},
};

const orgnisationDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ORGANISATION_DETAILS_ACTION:
      return {
        ...state,
        organisationData: action.data,
      };
    case actions.ORGANISATION_DETAILS_ACTION_SUCCESS:
      return {
        ...state,
        organisationData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default orgnisationDetailsReducer;
