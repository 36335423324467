import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    refreshEmployeeDetailsDispatcher,
    refreshUserPermissionsDispatcher,
} from "../store/dispatchers/authDispatcher";
import { getCookie } from "../utils/common";

const MasterdataWrapper = ({ children }) => {
    const token = useSelector((state) => state.auth.token);
    const sessionToken = getCookie("token");
    const dispatch = useDispatch();

    useEffect(() => {
        if (token && sessionToken) {
            dispatch(refreshUserPermissionsDispatcher);
            dispatch(refreshEmployeeDetailsDispatcher);
        }
    }, [token, sessionToken]);

    return <div>{children}</div>;
};

export default MasterdataWrapper;
