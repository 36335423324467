import * as actions from "../actions/types";

const initialState = {
  statusListData: [],
};

const statusListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.STATUS_LIST_ACTION:
      return {
        ...state,
        statusListData: action.data,
      };
    case actions.STATUS_LIST_ACTION_SUCCESS:
      return {
        ...state,
        statusListData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default statusListReducer;
