import * as actions from "./types";

// export const logout = () => ({
//   type: actions.LOGOUT_ACTION,
// });

// export const logoutSuccess = () => ({
//   type: actions.LOGOUT_ACTION_SUCCESS,
// });

export function logout(resolve, reject) {
  return {
    type: actions.LOGOUT_ACTION,
    // payload: param,
    resolve,
    reject,
  };
}
