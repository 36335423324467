import {
  SEARCH_LEADS_BY_DATE,
  SEARCH_LEADS_BY_DATE_SUCCESS,
  SEARCH_LEADS_BY_DATE_FAILED,
} from "../actions/types";

const initialState = {
  searchLeadsByDate: [],
};

const searchLeadsByDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_LEADS_BY_DATE:
      return {
        ...state,
        searchLeadsByDate: action.data,
      };
    case SEARCH_LEADS_BY_DATE_SUCCESS:
      return {
        ...state,
        searchLeadsByDate: action.data,
      };
    case SEARCH_LEADS_BY_DATE_FAILED:
      return {
        ...state,
        searchLeadsByDate: action.data,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
export default searchLeadsByDateReducer;
