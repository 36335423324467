import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import LayoutContext from "../../../Context/LayoutContext";
import Text from "../../ReusableComponents/Text";

const UnauthorizedPage = () => {
    const layoutContext = useContext(LayoutContext);
    const isLoggedIn = useSelector(state => state.auth.token)



    useEffect(() => {
        layoutContext.setDisabledTopNav(!isLoggedIn);
        layoutContext.setDisabledBottomNav(false);
    }, [isLoggedIn]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems={"center"}
            width={"100%"}
            height={"50vh"}
            justifyContent="center"
        >
            <Text fontSize={24} fontWeight={600}>
                You are not authorized!
            </Text>
            <Text>Please contact admin or your manager.</Text>
        </Box>
    );
};

export default UnauthorizedPage;
