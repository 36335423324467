import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  businessDetails: null,
  lead: null,
  leads: [],
  stages: [],
  pipeline: null,
  crifData: null,
  creditScore: null,
  obligation: null,
  obligationDetailed: null,
  loanOfferDetails: null,
  enquiries: [],
  consent: [],
  notes: [],
};

export const BusinessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    addBusinessData: (state, action) => {
      state.businessDetails = action.payload;
      const leadData = action.payload?.Leads?.length
        ? action.payload?.Leads[0]
        : null;
      state.lead = leadData;
      state.leads = action.payload?.Leads?.length ? action.payload?.Leads : [];
    },
    addStages: (state, action) => {
      state.stages = action.payload;
    },
    addPipelineData: (state, action) => {
      state.pipeline = action.payload;
    },
    setCreditScore: (state, action) => {
      state.creditScore = action.payload;
    },
    setCRIFData: (state, action) => {
      state.crifData = action.payload;
    },
    setObligation: (state, action) => {
      state.obligation = action.payload.obligation;
      state.obligationDetailed = action.payload.obligationDetailed;
    },
    setEnquiries: (state, action) => {
      state.enquiries = action.payload;
    },
    setConsent: (state, action) => {
      state.consent = action.payload;
    },
    setNotes: (state, action) => {
      state.notes = action.payload;
    },
    setLoanOffeDetails: (state, action) => {
      state.loanOfferDetails = action.payload;
    },
    resetSliceData: () => {
      state = initialState;
    },
  },
});

// * Selectors
export const selectNotes = (state) => state.business.notes;
export const selectConsent = (state) => state.business.consent;
export const selectEnquiries = (state) => state.business.enquiries;
export const selectStages = (state) => state.business.stages;
export const selectBusinessDetails = (state) => state.business.businessDetails;
export const selectLead = (state) => state.business.lead;
export const selectLeads = (state) => state.business.leads;
export const selectPipeline = (state) => state.business.pipeline;
export const selectCreditScore = (state) => state.business.creditScore;
export const selectCRIFData = (state) => state.business.crifData;
export const selectObligation = (state) => state.business.obligation;
export const selectObligationDetailed = (state) =>
  state.business.obligationDetailed;
export const selectLoanOfferDetails = (state) =>
  state.business.loanOfferDetails;
export const selectBusinessDocument = (state) =>
  state.business.lead?.Contact?.businessDocuments || [];
export const selectProfilePicture = (state) => {
  const documents = state.business.lead?.Contact?.BusinessDocuments || [];

  if (documents?.length) {
    const photographDocument = documents.filter(
      (doc) => doc.docType === "photograph"
    );
    let profilePicture = null;
    if (photographDocument.length) {
      profilePicture = `${process.env.REACT_APP_DOCUMENTS_BASE_URL}${photographDocument[0].docUpload}`;
    }
    return profilePicture;
  } else {
    return null;
  }
};
export const selectPreApprovedLoans = (state) => {
  const leads = state.business.leads;
  let preApprovedLoans = [];

  if (leads?.length) {
    let approvedLoggings;
    leads.forEach((lead) => {
      if (lead?.LeadLoggings?.length) {
        approvedLoggings = lead?.LeadLoggings.filter(
          (leadLog) => leadLog?.log_status === "Approved"
        );
        if (approvedLoggings.length) {
          approvedLoggings.forEach((approvedLog) => {
            preApprovedLoans.push({
              ...approvedLog,
              pipelineName: lead.pipelineName,
            });
          });
        }
      }
    });
    return preApprovedLoans;
  } else {
    return preApprovedLoans;
  }
};

export const {
  addBusinessData,
  addStages,
  addPipelineData,
  setCreditScore,
  setEnquiries,
  setConsent,
  setNotes,
  setCRIFData,
  setObligation,
  setLoanOffeDetails,
  resetSliceData,
} = BusinessSlice.actions;
export default BusinessSlice.reducer;
