import * as actions from "../actions/types";
const initialState = {
    cronGetStatusCheck:[]
};

const cronGetStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CRON_GET_STATUS:
            return {
                ...state,
                cronGetStatusCheck: action.data,
            };
        case actions.CRON_GET_STATUS_SUCCESS:
            return {
                ...state,
                cronGetStatusCheck: action.data,
            };
        case actions.CRON_GET_STATUS_ERROR:
            return {
                ...state,
                cronGetStatusCheck: action.data,
            };
        default:
            return state;
    }
};
export default cronGetStatusReducer;
