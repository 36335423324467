import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customerDetails: null,
    lead: null,
    leads: [],
    stages: [],
    pipeline: null,
    crifData: null,
    creditScore: null,
    crifScoreMsg: "",
    obligation: null,
    obligationDetailed: null,
    loanOfferDetails: null,
    enquiries: [],
    consent: [],
    notes: [],
    bankDetails: {},
};

export const CustomerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        addCustomerData: (state, action) => {
            state.customerDetails = action.payload;
            const leadData = action.payload?.Leads?.length ? action.payload?.Leads[0] : null;
            state.lead = leadData;
            state.leads = action.payload?.Leads?.length ? action.payload?.Leads : [];
        },
        addStages: (state, action) => {
            state.stages = action.payload;
        },
        addPipelineData: (state, action) => {
            state.pipeline = action.payload;
        },
        setCreditScore: (state, action) => {
            state.creditScore = action.payload;
        },
        setCRIFData: (state, action) => {
            state.crifData = action.payload;
        },
        setCRIFScoreMsg: (state, action) => {
            state.crifScoreMsg = action.payload;
        },
        setObligation: (state, action) => {
            state.obligation = action.payload.obligation;
            state.obligationDetailed = action.payload.obligationDetailed;
        },
        setEnquiries: (state, action) => {
            state.enquiries = action.payload;
        },
        setConsent: (state, action) => {
            state.consent = action.payload;
        },
        setNotes: (state, action) => {
            state.notes = action.payload;
        },
        setLoanOffeDetails: (state, action) => {
            state.loanOfferDetails = action.payload;
        },
        setBankDetails: (state, action) => {
            state.bankDetails = action.payload;
        },
        resetSliceData: () => {
            state = initialState;
        },
    },
});

// * Selectors
export const selectNotes = (state) => state.customer.notes;
export const selectConsent = (state) => state.customer.consent;
export const selectEnquiries = (state) => state.customer.enquiries;
export const selectStages = (state) => state.customer.stages;
export const selectCustomerDetails = (state) => state.customer.customerDetails;
export const selectLead = (state) => state.customer.lead;
export const selectLeads = (state) => state.customer.leads;
export const selectPipeline = (state) => state.customer.pipeline;
export const selectCreditScore = (state) => state.customer.creditScore;
export const selectCRIFData = (state) => state.customer.crifData;
export const selectCRIFScoreMsg = (state) => state.lead.crifScoreMsg;
export const selectObligation = (state) => state.customer.obligation;
export const selectObligationDetailed = (state) => state.customer.obligationDetailed;
export const selectLoanOfferDetails = (state) => state.customer.loanOfferDetails;
export const selectCustomerDocument = (state) => state.customer.customerDetails?.CustomerDocuments || [];
export const selectProfilePicture = (state) => {
    const documents = state.customer.lead?.Contact?.CustomerDocuments || [];

    if (documents?.length) {
        const photographDocument = documents.filter((doc) => doc.docType === "photograph");
        let profilePicture = null;
        if (photographDocument.length) {
            profilePicture = `${process.env.REACT_APP_DOCUMENTS_BASE_URL}${photographDocument[0].docUpload}`;
        }
        return profilePicture;
    } else {
        return null;
    }
};
export const selectPreApprovedLoans = (state) => {
    const leads = state.customer.leads;
    let preApprovedLoans = [];

    if (leads?.length) {
        let approvedLoggings;
        leads.forEach((lead) => {
            if (lead?.LeadLoggings?.length) {
                approvedLoggings = lead?.LeadLoggings.filter((leadLog) => leadLog?.log_status === "Approved");
                if (approvedLoggings.length) {
                    approvedLoggings.forEach((approvedLog) => {
                        preApprovedLoans.push({
                            ...approvedLog,
                            pipelineName: lead.pipelineName,
                        });
                    });
                }
            }
        });
        return preApprovedLoans;
    } else {
        return preApprovedLoans;
    }
};
export const selectBankDetails = (state) => state.lead.bankDetails;

export const {
    addCustomerData,
    addStages,
    addPipelineData,
    setCreditScore,
    setEnquiries,
    setConsent,
    setNotes,
    setCRIFData,
    setObligation,
    setLoanOffeDetails,
    resetSliceData,
    setCRIFScoreMsg,
    setBankDetails
} = CustomerSlice.actions;
export default CustomerSlice.reducer;
