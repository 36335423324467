import { Box, CircularProgress } from "@mui/material";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Router } from "react-router-dom";
import PrivateRoutes from "./Router/PrivateRoutes";
import PublicRoutes from "./Router/PublicRoutes";
import LayoutPage from "./components/Layout/Layout.react";
import { history } from "./store/index";
import { getCookie } from "./utils/common";

const Fallback = () => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" width={"100%"} height="100vh">
            <CircularProgress />
        </Box>
    );
};

const AppRouter = () => {
    const token = useSelector((state) => state.auth.token);
    const sessionToken = getCookie("token");

    return (
        <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
            <LayoutPage>
                <Suspense fallback={<Fallback />}>
                    {token && sessionToken ? <PrivateRoutes /> : <PublicRoutes />}
                </Suspense>
            </LayoutPage>
        </Router>
    );
};

export default AppRouter;
