import {
  LEAD_STAGE_LIST,
  LEAD_STAGE_LIST_SUCCESS,
  LEAD_STAGE_LIST_ERROR,
} from "../actions/types";

const initialState = {
  leadStageList: [],
};

const leadStageListReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEAD_STAGE_LIST:
      return {
        ...state,
        leadStageList: action.data,
      };
    case LEAD_STAGE_LIST_SUCCESS:
      return {
        ...state,
        leadStageList: action.data,
      };
    case LEAD_STAGE_LIST_ERROR:
      return {
        ...state,
        leadStageList: action.data,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
export default leadStageListReducer;
