import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import { updateAlertMsg } from "../../store/actions/commonAction";

// Task - List API
function timeLineDataStatusApi(params) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.TIMELINE_DATA_URL}`,
    // headers: {
    //     "Content-Type": "application/json",
    // },
    data: params,
  });
}

// Task-List action effect
export function* timeLineDataStatusEffect(taskListAction, props) {
  const { payload, resolve, reject } = taskListAction;
  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(timeLineDataStatusApi, payload);
    // yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);

      return yield put({
        type: actions.TIMELINE_DATA_SUCCESS,
        data,
      });
    } else {
      // yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    // yield put(toggleBackDrop(false));
    if (reject) reject(e);
    return yield put({
      type: actions.TIMELINE_DATA_ERROR,
      e,
    });
  }
}
// Task-List action watcher
export function* timeLineDataStatusWatcher() {
  yield takeLatest(actions.TIMELINE_DATA, timeLineDataStatusEffect);
}

export default function* rootSaga() {
  yield all([fork(timeLineDataStatusWatcher)]);
}
