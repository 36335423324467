// multiFilterLeadListSaga
import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

//Market Place domain API
function multiFilterLeadListApi(params) {
  let id = params.id;
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.MULTI_FILTER_LEAD_LIST}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: params,
  });
}

// Market Place domain effect

export function* multiFilterLeadListActionEffect(multiFilterLeadList) {
  const { payload, resolve, reject } = multiFilterLeadList;

  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(multiFilterLeadListApi, payload);
    // yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      // console.log("multiFilterLeadList", data)
      return yield put({
        type: actions.MULTI_FILTER_LEAD_LIST_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
    return yield put({
      type: actions.MULTI_FILTER_LEAD_LIST_ERROR,
      e,
    });
  }
}
//Market Place domain watcher
export function* multiFilterLeadListActionWatcher() {
  yield takeLatest(
    actions.MULTI_FILTER_LEAD_LIST,
    multiFilterLeadListActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(multiFilterLeadListActionWatcher)]);
}
