import { getCookie, isEmpty } from "../../utils/common";
const domain = getCookie("domain");
const access_token = getCookie("token");

export const headersCommonKey = {
  apiKey: "D2BDF6499CD925AB4DAF513BE589F",
  apiSecret: "F6D9851417DBA8492E25F6E6B43E9",
  contentType: { "Content-Type": "application/json" },
};
export const headersCommon = {
  domain_name: domain === "" ? "" : domain,
  apiKey: "D2BDF6499CD925AB4DAF513BE589F",
  apiSecret: "F6D9851417DBA8492E25F6E6B43E9",
  Authorization: `Bearer ${access_token || ""}`,
  "Content-Type": "application/json",
};
export const headersCommonNoContent = {
  domain_name: domain === "" ? "" : domain,
  apiKey: "D2BDF6499CD925AB4DAF513BE589F",
  apiSecret: "F6D9851417DBA8492E25F6E6B43E9",
  Authorization: `Bearer ${access_token || ""}`,
};
let newDomain = "";
if (window.location.host.includes("localhost")) {
  newDomain = "admin";
} else {
  newDomain = window.location.host.split(".")[0];
}

export const newHeader = {
  domain_name: domain === "" ? "" : newDomain,
  apiKey: "D2BDF6499CD925AB4DAF513BE589F",
  apiSecret: "F6D9851417DBA8492E25F6E6B43E9",
  Authorization: `Bearer ${access_token || ""}`,
};
