import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";

function applicationDashboardApi(param) {
  // console.log("edit stage template saga", paramID);
  const domain = getCookie("domain");
  // console.log("editapplicationDashboardApisaga1", param);
  let urlApplicationDash = `${process.env.REACT_APP_API_BASE_URL}${urls.GET_USER_APPLICATION_DASHBOARD}`;
  return axios.request({
    method: "post",
    url: urlApplicationDash,
    // headers: {
    //     "Content-Type": "application/json",
    //     "domain_name": domain === "lenders" ? "" : domain,
    //     "apiKey": headersKeyFile.headersCommonKey.apiKey,
    //     "apiSecret": headersKeyFile.headersCommonKey.apiSecret
    // },
    data: param,
  });
}

export function* applicationDashBoardEffect(applicationDash) {
  const { payload, resolve, reject } = applicationDash;
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(applicationDashboardApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.DYNAMIC_APPLICATION_DASHBOARD_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
    return yield put({
      type: actions.DYNAMIC_APPLICATION_DASHBOARD_ERROR,
      e,
    });
  }
}

export function* applicationDashBoardWatcher() {
  yield takeLatest(
    actions.DYNAMIC_APPLICATION_DASHBOARD,
    applicationDashBoardEffect
  );
}

export default function* rootSaga() {
  yield all([fork(applicationDashBoardWatcher)]);
}
