import axios from "../../axios";
import {
  BASE_URL,
  PROFILE_VERIFICATION_EMAIL_SEND,
  PROFILE_EMAIL_VERIFICATION,
  PROFILE_PHONE_SEND_OTP,
  PROFILE_PHONE_VERIFICATION,
  SEND_USER_VERIFICATION_EMAIL,
  VERIFY_USER_VER_EMAIL,
  SEND_USER_VER_PHONE,
  VERIFY_USER_VER_PHONE,
  USER_PROFILE_ACTION,
  BASE64_IMAGE,
} from "../../../utils/urls";

const sendProfileVerEmail = async (data) => {
  console.log("Payload : ", data);
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${PROFILE_VERIFICATION_EMAIL_SEND}`,
    data
  );
  console.log("inside sendProfileVerEmail res=>", res);
  return res;
};

const profileEmailVer = async (data) => {
  console.log("Payload : ", data);
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${PROFILE_EMAIL_VERIFICATION}`,
    data
  );
  console.log("inside profileEmailVer res=>", res);
  return res;
};

const resendProfilePhoneOTP = async (data) => {
  console.log("Payload : ", data);
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${PROFILE_PHONE_SEND_OTP}`,
    data
  );
  console.log("inside resendProfilePhoneOTP res=>", res);
  return res;
};

const verifyProfilePhoneOTP = async (data) => {
  console.log("Payload : ", data);
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${PROFILE_PHONE_VERIFICATION}`,
    data
  );
  console.log("inside verifyProfilePhoneOTP res=>", res);
  return res;
};

const sendUserVerEmail = async (data) => {
  console.log("Payload : ", data);
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${SEND_USER_VERIFICATION_EMAIL}${data.id}`,
    data
  );
  console.log("inside sendUserVerEmail res=>", res);
  return res;
};

const verifyUserVerEmail = async (data) => {
  console.log("Payload : ", data);
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${VERIFY_USER_VER_EMAIL}${data.id}`,
    data
  );
  console.log("inside verifyUserVerEmail res=>", res);
  return res;
};

const sendUserVerPhone = async (data) => {
  console.log("Payload : ", data);
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${SEND_USER_VER_PHONE}${data.id}`,
    data
  );
  console.log("inside sendUserVerPhone res=>", res);
  return res;
};

const verifyUserVerPhone = async (data) => {
  console.log("Payload : ", data);
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${VERIFY_USER_VER_PHONE}${data.id}`,
    data
  );
  console.log("inside verifyUserVerPhone res=>", res);
  return res;
};

const getProfileData = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}${USER_PROFILE_ACTION}`
  );
  return res?.data;

};

const base46ImgProfile = async (data) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${BASE64_IMAGE}`,
    data
  );
  return res?.data;
};

// const createSourceLink = async (data) => {
//   const res = await axios.post(
//     `${process.env.REACT_APP_API_BASE_URL}${ADD_API_KEY}`,
//     data
//   );
//   return res?.data;
// };

// console.log("getProfileData Test =>", getProfileData);

export {
  sendProfileVerEmail,
  profileEmailVer,
  resendProfilePhoneOTP,
  verifyProfilePhoneOTP,
  getProfileData,
  sendUserVerEmail,
  verifyUserVerEmail,
  sendUserVerPhone,
  verifyUserVerPhone,
  base46ImgProfile,
};
