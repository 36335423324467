import * as actions from "../actions/types";

const initialState = {
  pipelineArchiveDelete: [],
};

const pipelineArchiveDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.PIPELINE_ARCHIVE_DELETE:
      return {
        ...state,
        // pipelineArchiveDelete: [],
      };
    case actions.PIPELINE_ARCHIVE_DELETE_SUCCESS:
      return {
        ...state,
        pipelineArchiveDelete: action.data,
      };
    case actions.PIPELINE_ARCHIVE_DELETE_ERROR:
      return {
        ...state,
        pipelineArchiveDelete: [],
      };
    default: {
      return {
        ...state,
        pipelineArchiveDelete: [],
      };
    }
  }
};
export default pipelineArchiveDeleteReducer;
