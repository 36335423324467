import * as actions from "../actions/types";

const initialState = {
  getAccessLevelDataByRole: [],
};

const accessLevelReducerByRole = (state = initialState, action) => {
  // console.log("action", action);
  switch (action.type) {
    case actions.ACCESS_LEVEL_ACTION_BY_ROLE:
      return {
        ...state,
        getAccessLevelDataByRole: action.payload,
      };
    case actions.ACCESS_LEVEL_ACTION_BY_ROLE_SUCCESS:
      return {
        ...state,
        getAccessLevelDataByRole: action.payload,
      };
    default: {
      return state;
    }
  }
};
export default accessLevelReducerByRole;
