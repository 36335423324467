import * as actions from "../actions/types";
import * as urls from "../../utils/urls";

import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { getCookie, isEmpty } from "../../utils/common";

import _ from "lodash";
import axios from "../../utils/axios";
import { toggleBackDrop } from "../actions/commonAction";

// secret - List API
function secretKeyListApi(params) {
  // console.log(params, "secret key list saga params logs");
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.SECRET_KEYS}/${params}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// secret-List action effect
export function* secretkeyListActionEffect(keyListAction) {
  const { payload, resolve, reject } = keyListAction;

  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(secretKeyListApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.SECRET_LIST_ACTION_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// key-List action watcher
export function* secretKeyListActionWatcher() {
  yield takeLatest(actions.SECRET_LIST_ACTION, secretkeyListActionEffect);
}

export default function* rootSaga() {
  yield all([fork(secretKeyListActionWatcher)]);
}
