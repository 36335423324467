import * as actions from "../actions/types";

const initialState = {
   timeLineStatus: [],
};

const getTimeLineDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.TIMELINE_DATA:
      return {
        ...state,
        timeLineStatus: action.data,
      };
    case actions.TIMELINE_DATA_SUCCESS:
      return {
        ...state,
        timeLineStatus: action.data,
      };
      case actions.TIMELINE_DATA_ERROR:
        return {
          ...state,
          timeLineStatus: action.data,
        };
    default: {
      return state;
    }
  }
};
export default getTimeLineDataReducer;
