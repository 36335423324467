import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Update task API
function pipelineShareApi(params) {
  // console.log(params);
  let id = params.id;
  let shareData = { share: params.shareData };
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}${
      urls.SHARE_PIPELINE
    }/${id}/${"share"}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: shareData,
  });
}

// Update task action effect
export function* pipelineShareActionEffect(pipelineShareAction) {
  const { payload, resolve, reject } = pipelineShareAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(pipelineShareApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Update task action watcher
export function* pipelineShareActionWatcher() {
  yield takeLatest(actions.PIPELINE_SHARE, pipelineShareActionEffect);
}

export default function* rootSaga() {
  yield all([fork(pipelineShareActionWatcher)]);
}
