import * as actions from "../actions/types";

const initialState = {
  pipelineListData: [],
};

const pipelineListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.PIPELINE_ACTION:
      return {
        ...state,
        pipelineListData: action.data,
      };
    case actions.PIPELINE_ACTION_SUCCESS:
      return {
        ...state,
        pipelineListData: action.data,
      };
      case actions.PIPELINE_ACTION_ERROR:
        return {
          ...state,
          pipelineListData: action.data,
        };
    default: {
      return state;
    }
  }
};
export default pipelineListReducer;
