import {
    DELETE_BORROWER,
    GET_COMPANY_LOGO,
    GET_OTP_CUSTOMER,
    VERIFY_OTP_FOR_CSUSTOMER_DELETE,
} from "../../../utils/urls";
import axios from "../../axios";

const getCompanyLogo = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}${GET_COMPANY_LOGO}`);
    return res?.data;
};

const sendOtpToCustomer = async (data) => {
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}${GET_OTP_CUSTOMER}`, data);
    return res;
};

const verifyCustomerForDelete = async (data) => {
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}${VERIFY_OTP_FOR_CSUSTOMER_DELETE}`, data);
    return res;
};

const deleteBorrower = async (data) => {
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}${DELETE_BORROWER}`, data);
    return res;
};

export { deleteBorrower, getCompanyLogo, sendOtpToCustomer, verifyCustomerForDelete };
