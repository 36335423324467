import * as actions from "../actions/types";
import * as urls from "../../utils/urls";

import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { getCookie, isEmpty } from "../../utils/common";

import _ from "lodash";
import axios from "../../utils/axios";
import { toggleBackDrop } from "../actions/commonAction";

// user profile - List API
function UserProfileApi(id) {
  if (id === undefined) {
    return axios.request({
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}${urls.USER_PROFILE_ACTION}`,
      // headers: {
      //   "Content-Type": "application/json",
      // },
    });
  } else {
    return axios.request({
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}${urls.OTHER_USER_PROFILE_ACTION}/${id}`,
      // headers: {
      //   "Content-Type": "application/json",
      // },
    });
  }
}

// user profile action effect
export function* userProfileActionEffect(userprofileAction) {
  const { payload, resolve, reject } = userprofileAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(UserProfileApi, payload);
    // console.log("User Profile Data ------------ ", data);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.USER_PROFILE_ACTION_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// user profile action watcher
export function* userProfileActionWatcher() {
  yield takeLatest(actions.USER_PROFILE_ACTION, userProfileActionEffect);
}

export default function* rootSaga() {
  yield all([fork(userProfileActionWatcher)]);
}
