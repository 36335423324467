import * as actions from "../actions/types";

const initialState = {
  domainList: {},
};

const saveMarketPlaceDomainReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.MARKET_PLACE_DOMAIN:
      return {
        ...state,
        domainList: action.data,
      };
    case actions.MARKET_PLACE_DOMAIN_SUCCESS:
      return {
        ...state,
        domainList: action.data,
      };
      case actions.MARKET_PLACE_DOMAIN_ERROR:
      return {
        ...state,
        domainList: action.data,
      };
    default: {
      return state;
    }
  }
};
export default saveMarketPlaceDomainReducer;
