import * as actions from "../actions/types";

const initialState = {
    domain_name: "",
    employee: {},
    permissions: {},
    permissionsByModuleWise: {},
    permissionsByModuleWiseByKeys: {},
    allPermissions: [],
    permissionKeys: {
        sidebar: {},
        pages: {},
        permissions: {},
        permissionDetails: {},
    },
    token: "",
    typeName: "",
    userType: "",
    organizationLogo: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.USER_AUTH_DETAILS:
            // const permissionsByModuleWise = {};
            // const permissionsByModuleWiseByKeys = {};

            // action.payload.permissionsByModuleWise.map((module) => {
            //   permissionsByModuleWise[module.permission_name] = {
            //     ...module,
            //   };
            //   permissionsByModuleWiseByKeys[module.permission_name] = {};

            //   module.AccessLevelPermissions.map((item) => {
            //     permissionsByModuleWiseByKeys[module.permission_name] = {
            //       ...permissionsByModuleWiseByKeys[module.permission_name],
            //       [item.model_name]: item,
            //     };
            //   });
            // });
            const allPermissions = action.payload.permissionsByModuleWise;
            const permissionKeys = {
                sidebar: {},
                pages: {},
                permissions: {},
                permissionDetails: {},
            };

            allPermissions.map((sidebar) => {
                const obj = {
                    ...sidebar,
                };
                delete obj.pages;
                permissionKeys.sidebar[sidebar.sidebar_name] = obj;

                // set pages
                sidebar.pages.map((page) => {
                    const obj = {
                        ...page,
                    };
                    delete obj.permissions;
                    permissionKeys.pages[page.page_name] = obj;

                    // set permissions
                    page.permissions.map((permission) => {
                        const obj = {
                            ...permission,
                        };
                        delete obj.permissionDetails;
                        permissionKeys.permissions[permission.permission_name] = obj;

                        // set permission details
                        permission.permissionDetails.map((permissionDetail) => {
                            permissionKeys.permissionDetails[permissionDetail.permission_details_name] =
                                permissionDetail;
                        });
                    });
                });
            });

            return {
                ...state,
                ...action.payload,
                allPermissions,
                permissionKeys,
                userType: action.payload.typeName,
            };
        case actions.SET_ORGANIZATION_LOGO:
            return {
                ...state,
                organizationLogo: action.payload,
            };
        case actions.RESET_USER_AUTH_DETAILS:
            return initialState;

        case actions.REFRESH_EMPLOYEE_DETAILS:
            return {
                ...state,
                employee: {
                    ...state.employee,
                    ...action.payload,
                },
            };

        default: {
            return state;
        }
    }
};
export default authReducer;
