import * as actions from "../actions/types";

const initialState = {
  bankListData: [],
};

const banklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.BANK_LIST_ACTION:
      return {
        ...state,
        bankListData: action.data,
      };
    case actions.BANK_LIST_ACTION_SUCCESS:
      return {
        ...state,
        bankListData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default banklistReducer;
