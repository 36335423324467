import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import axios from "../../utils/axios";
import { isEmpty } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as actions from "../actions/types";

// Role - List API
function RoleListApi() {
    return axios.request({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}/access_level/get-roles`,
        // headers: {
        //   "Content-Type": "application/json",
        // },
    });
}

// Role-List action effect
export function* roleListActionEffect(taskListAction) {
    const { payload, resolve, reject } = taskListAction;

    try {
        yield put(toggleBackDrop(true));
        const { data } = yield call(RoleListApi, payload);
        yield put(toggleBackDrop(false));
        if (!isEmpty(data)) {
            if (resolve) resolve(data);
            return yield put({
                type: actions.ROLE_LIST_ACTION_SUCCESS,
                data,
            });
        } else {
            yield put(toggleBackDrop(false));
            reject(data);
        }
    } catch (e) {
        yield put(toggleBackDrop(false));
        if (reject) reject(e);
    }
}
// Role-List action watcher
export function* roleListActionWatcher() {
    yield takeLatest(actions.ROLE_LIST_ACTION, roleListActionEffect);
}

export default function* rootSaga() {
    yield all([fork(roleListActionWatcher)]);
}
