import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { Button, InputAdornment, TextField } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getCompanyLogo, sendOtpToCustomer } from "../../api/services/DeleteBorrower";
import roopya_logo from "../../assets/img/roopya_logo_original_colour.png";
import styles from "./PhoneVerification.module.css";

const PhoneVerification = () => {
    const [phone, setPhone] = useState("");
    const [error, setError] = useState("");
    const history = useHistory();

    const companyLogoDataAPI = useQuery(["getCompanyLogo"], getCompanyLogo, {
        refetchOnWindowFocus: false,
        retry: false,
    });

    const companyLogoData = companyLogoDataAPI?.data?.data?.documents;

    const handleSendOtpSuccess = (data) => {
        const contact_id = data?.data?.data?.contact.id;

        if (contact_id) {
            history.push(`/delete-borrower-account/${contact_id}`);
        }
    };

    const sendOtpToCustomerAPI = useMutation({
        mutationKey: ["sendOtpToCustomer"],
        mutationFn: (payload) => sendOtpToCustomer(payload),
        onSuccess: handleSendOtpSuccess, // Passes data automatically to handleSendOtpSuccess
        onError: () => {
            toast.error("This Customer Not Exist!");
        },
        refetchOnWindowFocus: false,
    });

    const validatePhone = (value) => {
        if (value.length !== 10) return "Phone number must be 10 digits";
        if (!/^[6-9]\d{9}$/.test(value)) return "Phone number must start with 6-9";
        if (/^(\d)\1{9}$/.test(value)) return "Phone number cannot contain all identical digits";
        return "";
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setPhone(value);
        setError(validatePhone(value));
    };

    const handleSendOtp = () => {
        if (!error && phone) {
            sendOtpToCustomerAPI.mutate({ phone });
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.innerBox}>
                <img
                    src={`${process.env.REACT_APP_DOCUMENTS_BASE_URL}${companyLogoData}`}
                    alt="Company Logo"
                    className={styles.logo}
                />
                <h4 className={styles.title}>Phone Verification</h4>
                <div>
                    <TextField
                        label="Enter Mobile Number"
                        variant="outlined"
                        fullWidth
                        value={phone}
                        type="number"
                        onChange={handlePhoneChange}
                        error={!!error}
                        helperText={error}
                        className={styles.input}
                        style={{ margin: "2rem 0" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{ marginRight: "8px" }}>
                                    <PhoneIphoneIcon />
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            shrink: !!phone.length > 0 || undefined,
                        }}
                    />
                </div>

                <Button
                    variant="contained"
                    onClick={handleSendOtp}
                    disabled={!!error || !phone || sendOtpToCustomerAPI.isLoading}
                    className={styles.verifyButton}
                    style={{
                        padding: "7px 0",
                        fontSize: "1rem",
                        cursor: !!error || !phone ? "not-allowed" : "pointer",
                        backgroundColor: !!error || !phone ? "#c7c7c7" : "#2e3092",
                        color: "#FFFFFF",
                    }}
                >
                    {sendOtpToCustomerAPI.isLoading ? "Sending..." : "Continue"}
                </Button>
                <span className={styles.textShowDisp}>Verify Your Identity to Delete Your Account</span>
                <div className={styles.poweredBy}>
                    <span>Powered By</span>
                    <img src={roopya_logo} alt="Small Logo" className={styles.smallLogo} />
                </div>
            </div>
        </div>
    );
};

export default PhoneVerification;
