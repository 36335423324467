import * as actions from "../actions/types";

const initialState = {
  workSpacetData: [],
};

const workSpaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.WORK_SPACE_ACTION:
      return {
        ...state,
        workSpacetData: action.data,
      };
    case actions.WORK_SPACE_ACTION_SUCCESS:
      return {
        ...state,
        workSpacetData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default workSpaceReducer;
