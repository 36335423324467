import DeleteIcon from "@mui/icons-material/Delete"; // Import DeleteIcon
import { Box, Button, TextField } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteBorrower, getCompanyLogo, verifyCustomerForDelete } from "../../api/services/DeleteBorrower";
import roopya_logo from "../../assets/img/roopya_logo_original_colour.png";
import styles from "./PhoneVerification.module.css";

// Custom OTP input component
const OTPInput = ({ length, onChange, error }) => {
    const [otp, setOtp] = useState(Array(length).fill(""));

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === "") {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            onChange(newOtp.join(""));

            // Move to the next input if current one is filled
            if (value !== "" && index < length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                {otp.map((digit, index) => (
                    <TextField
                        key={index}
                        id={`otp-input-${index}`}
                        value={digit}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        inputProps={{
                            maxLength: 1,
                            style: { textAlign: "center", width: "19px", height: "9px" },
                        }}
                        sx={{ mx: 1 }}
                    />
                ))}
            </Box>
            {error && <p className={styles.errorText}>{error}</p>}
        </Box>
    );
};

const DeleteBorrowerAccount = () => {
    const [otp, setOtp] = useState("");
    const [error, setError] = useState("");
    const history = useHistory();

    const { id } = useParams();

    useEffect(() => {
        console.log("ID from URL:", id); // Use this ID as needed in your component
        // You can now use `id` to fetch data or perform any actions based on the URL parameter
    }, [id]);

    const companyLogoDataAPI = useQuery(["getCompanyLogo"], getCompanyLogo, {
        refetchOnWindowFocus: false,
        retry: false,
    });

    const companyLogoData = companyLogoDataAPI?.data?.data?.documents;

    const handleOtpChange = (otpValue) => {
        setOtp(otpValue);
        setError(otpValue.length === 4 ? "" : "OTP must be 4 digits");
    };

    const deleteBorrowerAPI = useMutation({
        mutationKey: ["deleteBorrower"],
        mutationFn: (payload) => deleteBorrower(payload),
        onSuccess: () => toast.success("Successfully deleted borrower account"),
        onError: (error) => {
            toast.error(error.msg);
        },
        refetchOnWindowFocus: false,
    });

    const handleVerifyOtpSuccess = (data) => {
        const status = data?.data?.statusCode;
        const message = data?.data?.message;

        if (!(status === 400 || status === 401 || status === 403 || status === 404 || status === 500)) {
            deleteBorrowerAPI.mutate({
                contact_id: id,
            });
        } else {
            toast.error(message);
        }
    };

    const verifyCustomerForDeleteAPI = useMutation({
        mutationKey: ["verifyCustomerForDelete"],
        mutationFn: (payload) => verifyCustomerForDelete(payload),
        onSuccess: handleVerifyOtpSuccess, // Passes data automatically to handleSendOtpSuccess
        onError: (error) => {
            toast.error(error.msg);
        },
        refetchOnWindowFocus: false,
    });

    const deleteAccount = () => {
        if (otp.length !== 4) {
            setError("Please enter a valid 4-digit OTP.");
            return;
        }

        try {
            if (!error && otp) {
                verifyCustomerForDeleteAPI.mutate({
                    phone_otp: otp,
                    id: id,
                });
            }
        } catch (error) {
            alert("Error processing request.");
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.innerBox}>
                <div className={styles.logoContainer2}>
                    <img
                        src={`${process.env.REACT_APP_DOCUMENTS_BASE_URL}${companyLogoData}`}
                        alt="Company Logo"
                        className={styles.logo}
                    />
                    <DeleteIcon sx={{ color: "#ee0505", fontSize: "2rem", mt: 2 }} /> {/* Icon added */}
                </div>

                <h3 className={styles.title2}>Delete Account</h3>
                <h6 className={styles.titleH6}>Are you sure you want to delete your account?</h6>

                <p className={styles.textShowDisp2}>
                    <span className={styles.textShowDispWarning}>WARNING! :</span>
                    This action is permanent and your account cannot be recovered once deleted. Please enter the OTP to
                    proceed.
                </p>

                <OTPInput length={4} onChange={handleOtpChange} error={error} />

                <Button
                    variant="contained"
                    onClick={deleteAccount}
                    disabled={otp.length !== 4 || !!error}
                    className={styles.deleteButton}
                    style={{
                        backgroundColor: otp.length !== 4 || !!error ? "gray" : "rgb(206 28 28)",
                        color: "#FFFFFF",
                        padding: "10px 20px",
                        fontSize: "1rem",
                    }}
                >
                    Delete Account
                </Button>

                <div className={styles.poweredBy}>
                    <span>Powered By</span>
                    <img src={roopya_logo} alt="Small Logo" className={styles.smallLogo} />
                </div>
            </div>
        </div>
    );
};

export default DeleteBorrowerAccount;
