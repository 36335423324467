import * as actions from "../actions/types";

const initialState = {
    addGlobalNotesData: [],
};

const addGlobalNotesDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.ADD_GLOBAL_DATA_NOTES:
            return {
                ...state,
                addGlobalNotesData: action.data,
            };
        case actions.ADD_GLOBAL_DATA_NOTES_SUCCESS:
            return {
                ...state,
                addGlobalNotesData: action.data,
            };
        case actions.ADD_GLOBAL_DATA_NOTES_ERROR:
            return {
                ...state,
                addGlobalNotesData: action.data,
            };
        default: {
            return state;
        }
    }
};
export default addGlobalNotesDataReducer;
