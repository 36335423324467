import { call, put, takeLatest, all, fork } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// access level - List API
function AccessLevelRoleApi(id) {
  // console.log("AccessLevelRoleApi", id);
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.GET_ACCESS_LEVEL_BY_ROLE}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// access-Level action effect
export function* accessLevelActionEffect(stageListAction) {
  const { payload, resolve, reject } = stageListAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(AccessLevelRoleApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.ACCESS_LEVEL_ACTION_BY_ROLE_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// access level action watcher
export function* accessLevelActionWatcher() {
  yield takeLatest(
    actions.ACCESS_LEVEL_ACTION_BY_ROLE,
    accessLevelActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(accessLevelActionWatcher)]);
}
