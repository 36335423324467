import RefreshIcon from "@mui/icons-material/Refresh";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Text from "./ReusableComponents/Text";

const ErrorFallback = () => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Box
      width={"100%"}
      minHeight={"80vh"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Text fontSize={32}>Oops, Something went wrong!</Text>
        <Text fontSize={18}>
          Try reloading this page. It might solve this issue.
        </Text>
      </Box>
      <Box mt={4}>
        <Button onClick={handleReload} disableElevation variant="contained">
          Reload
          <RefreshIcon sx={{ ml: 1 }} />
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorFallback;
