import React, { useContext, useEffect, useState } from "react";

import { gkData, gkDataSuccess } from "../../store/actions/gkData";
import { workSpace, workSpaceSuccess } from "../../store/actions/workSpace";
import { getCookie, setCookie } from "../../utils/common";
// import { Formik } from "formik";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import LayoutContext from "../../Context/LayoutContext";
import { getAccessLevel } from "../../store/actions/accessLevel";
import { updateAlertMsg } from "../../store/actions/commonAction";
import { loginUser } from "../../store/actions/login";
import { resetPassword } from "../../store/actions/resetPassword";

import LoginPageBackground from "../../assets/img/LoginPageBackground.jpg";
import roopyaMoneyLogo from "../../assets/img/roopyacustomlogo.svg";
import styles from "./Signin.module.css";
// import forgetpassLogo from "../../assets/img/forget_pass_logo.svg";
// import { useMutation } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Loader } from "rsuite";
// import { toast } from "react-toastify";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import { FormHelperText } from "@mui/material";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import routes from "../../constants/Routes/routes";
import { resetUserAuthDetailsDispatcher, userAuthDetailsDispatcher } from "../../store/dispatchers/authDispatcher";
import { loginApi } from "../../store/sagas/loginSaga";

const SigninPage = (props) => {
    const layoutContext = useContext(LayoutContext);
    const domain = getCookie("domain");
    const history = useHistory();
    // const [loading, setLoading] = useState(false);
    const currentSubdomain = window.location.host.split(".")[0];
    const loginDataFromCookies = getCookie("loginData") ? JSON.parse(getCookie("loginData")) : null;

    // useLayoutEffect(() => {
    //   layoutContext.setDisabledTopNav(true);
    //   layoutContext.setDisabledBottomNav(true);
    //   if (getCookie("token")) {
    //     if (window.location.host.includes("localhost")) {
    //       history.push("/dashboard");
    //     } else {
    //       window.location.replace(
    //         `https://${domain}.${process.env.REACT_APP_BASE_URL}/#/dashboard`
    //       );
    //     }
    //   }
    // }, []);

    const [placeEmailHold, setPlaceEmailHold] = useState("Enter Your Email");
    const [placePassHold, setPlacePassHold] = useState("Enter Your Password");
    const addclass = () => {
        document.querySelector("#label-email-Cre-Inst").classList.add(`${styles.fieldSelect}`);
        if (placeEmailHold === "Enter Your Email") setPlaceEmailHold(" ");

        document.querySelector("#label-password-Cre-Inst").classList.add(`${styles.fieldSelect}`);
        if (placePassHold === "Enter Your Password") setPlacePassHold(" ");
    };

    // const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useDispatch();

    const [isVisible, setVisible] = useState(false);
    const toggle = () => {
        setVisible(!isVisible);
    };

    const [errorDetails, setErrorDetails] = React.useState({
        email: "",
        password: "",
    });

    const [details, setDetails] = React.useState({
        email: "",
        password: "",
        provider: "custom",
    });

    const onSignDataChange = (e) => {
        // setIsLoading(false);
        setDetails({ ...details, [e.target.name]: e.target.value });

        // if (e.target.value.length !== 0) {
        //   if (e.target.name === "email" && !e.target.value.match(reg)) {
        //     setErrorDetails({
        //       ...errorDetails,
        //       email: "Invalid email",
        //     });
        //     return;
        //   }
        // }

        // else

        if (e.target.name === "password" && e.target.value.length === 0) {
            setErrorDetails({
                ...errorDetails,
                password: "Please enter your password",
            });
            return;
        }

        // else {
        setIsLoading(false);
        setErrorDetails({ ...errorDetails, [e.target.name]: "" });
        // }
    };

    const letLoginUser = async (formData) => {
        console.log("logintest", window.location);

        loginApi(formData)
            .then((response) => {
                setIsLoading(false);
                const data = response.data;

                if (currentSubdomain === "lenders") {
                    const loginData = {
                        email: details.email,
                        password: details.password,
                        provider: details.provider,
                    };
                    setCookie("loginData", JSON.stringify(loginData), 1);
                    window.location.replace(
                        `https://${data.data.domain_name}.${
                            process.env.REACT_APP_BASE_URL
                        }/#${routes.publicRoutes.SigninPage.getUrl()}`
                    );
                    return;
                }

                const { data: loginData } = data;
                const { token, domain_name, typeName, employee } = loginData;

                setCookie("domain", domain_name, 1, window.location.host);
                setCookie("token", token, 1, window.location.host);
                dispatch(userAuthDetailsDispatcher(data.data));

                const role_id = loginData?.employee?.role_id;

                const { employee: userDetails = {} } = loginData;
                const { permissions: employeepermission = {} } = loginData;

                const BankCredential = employeepermission?.BankCredential || false;
                const BankCredentialHistory = employeepermission?.BankCredentialHistory || false;
                const AutoLogin = employeepermission?.AutoLogin || false;
                const AutoLoginDetails = employeepermission?.AutoLoginDetails || false;
                const Contact = employeepermission?.Contact || "";
                const Pipeline = employeepermission?.Pipeline || "";
                const SecretKey = employeepermission?.SecretKey || "";
                const EmployeTree = employeepermission?.EmployeeTree || "";
                const StageTemplate = employeepermission?.StageTemplate || "";
                const LeadLogStatus = employeepermission?.LeadLogStatus || "";
                const CustomerPIMaster = employeepermission?.CustomerPIMaster || "";
                const CustomerFinancialPIMaster = employeepermission?.CustomerFinancialPIMaster || "";
                const CustomerDetailsPiMaster = employeepermission?.CustomerDetailsPiMaster || "";
                const CustomerEnquiryPiMaster = employeepermission?.CustomerEnquiryPiMaster || "";

                const Employee = employeepermission.Employee || "";
                const Leads = employeepermission.Leads || "";
                const LeadAccess = employeepermission.LeadAccess || "";
                const UserPipeline = employeepermission.Pipeline || "";
                const BankList = employeepermission.BankList || "";
                const BusinessRule = employeepermission.BusinessRule || "";
                const Setting = employeepermission.Setting || "";
                const SecretKeyPipeline = employeepermission.SecretKeyPipeline || "";
                const SharedPipeline = employeepermission.SharedPipeline || "";
                const Permission = employeepermission.Permission || "";

                const { Task } = employeepermission;
                const { Dashboard } = employeepermission;
                const { Role } = employeepermission;
                const { id } = userDetails;
                const { fullName } = employee;
                const { firstName } = userDetails;
                const { colorCode } = userDetails;
                const { email } = userDetails;
                localStorage.setItem("domain", domain_name);

                // ---------- set all permissions in cookies starts -----------
                // Object.keys(loginData.permissions).map((key) => {
                //   setCookie(
                //     `permission_${key}`,
                //     JSON.stringify(loginData.permissions[key]),
                //     1
                //   );
                // });

                // loginData.permissionsByModuleWise.map((module) => {
                //   setCookie(
                //     `permissionsByModuleWise_${module.permission_name}`,
                //     JSON.stringify(module),
                //     1
                //   );
                // });
                // ---------- set all permissions in cookies ends -----------

                setCookie("bankCredentialHistory", JSON.stringify(BankCredentialHistory), 1, window.location.host);
                setCookie("autoLogin", JSON.stringify(AutoLogin), 1, window.location.host);
                setCookie("autoLoginDetails", JSON.stringify(AutoLoginDetails), 1, window.location.host);
                setCookie("customerPIMaster", JSON.stringify(CustomerPIMaster), 1, window.location.host);
                setCookie(
                    "customerFinancialPIMaster",
                    JSON.stringify(CustomerFinancialPIMaster),
                    1,
                    window.location.host
                );
                setCookie("customerDetailsPiMaster", JSON.stringify(CustomerDetailsPiMaster), 1, window.location.host);
                setCookie("customerEnquiryPiMaster", JSON.stringify(CustomerEnquiryPiMaster), 1, window.location.host);

                setCookie("role_id", role_id, 1, window.location.host);
                setCookie("fullName", fullName, 1, window.location.host);
                setCookie("typeName", JSON.stringify(typeName), 1, window.location.host);
                setCookie("bankCredential", JSON.stringify(BankCredential), 1, window.location.host);
                setCookie("secretKey", JSON.stringify(SecretKey), 1, window.location.host);
                setCookie("employeTree ", JSON.stringify(EmployeTree), 1, window.location.host);
                setCookie("stageTemplate ", JSON.stringify(StageTemplate), 1, window.location.host);
                setCookie("leadLogStatus ", JSON.stringify(LeadLogStatus), 1, window.location.host);
                setCookie("user_id", id, 1, window.location.host);
                setCookie("name", firstName, 1, window.location.host);
                setCookie("colorCode", colorCode, 1, window.location.host);
                setCookie("email", email, 1, window.location.host);
                setCookie("leads_permission", JSON.stringify(Leads, 1, window.location.host));
                setCookie("lead_access", JSON.stringify(LeadAccess, 1, window.location.host));
                setCookie("pipeline_permission", JSON.stringify(Pipeline, 1, window.location.host));
                setCookie("customer_permission", JSON.stringify(Contact, 1, window.location.host));
                setCookie("bank_permission", JSON.stringify(BankList, 1, window.location.host));
                setCookie("businessRule_permission", JSON.stringify(BusinessRule, 1, window.location.host));
                setCookie("employee_permission", JSON.stringify(Employee, 1, window.location.host));
                setCookie("user_permission", JSON.stringify(UserPipeline, 1, window.location.host));
                setCookie("setting_permission", JSON.stringify(Setting, 1, window.location.host));
                setCookie("task_permission", JSON.stringify(Task, 1, window.location.host));
                setCookie("role_permission", JSON.stringify(Role, 1, window.location.host));
                setCookie("permission", JSON.stringify(Permission, 1, window.location.host));
                setCookie("dashboard_permission", JSON.stringify(Dashboard, 1, window.location.host));
                setCookie("secretKey_permission", JSON.stringify(SecretKeyPipeline, 1, window.location.host));
                setCookie("shared_pipeline", JSON.stringify(SharedPipeline, 1, window.location.host));

                // props.getAccessLevel();
                history.push(routes.privatePages.DashboardIndexPage.getUrl());
            })
            .catch((err) => {
                const { response } = err;
                console.log("Res=>", { response, err });
                console.error(err);

                const { data } = response;

                setIsLoading(false);
                setIsDisabled(false);
                props.updateAlertMsg({
                    message: (data && data.message) || "Something went wrong. Please try again!",
                    messageType: "error",
                });
            });
    };

    const handleSubmit = () => {
        if (details.email === "") {
            setErrorDetails({
                ...errorDetails,
                email: "Please enter your email id",
            });
        } else if (details.password === "") {
            setErrorDetails({
                ...errorDetails,
                password: "Please enter your password",
            });
        } else {
            setIsDisabled(true);
            setIsLoading(true);
            letLoginUser(details);
        }
    };

    useEffect(() => {
        if (loginDataFromCookies) {
            setCookie("loginData", "", 0);
            letLoginUser(loginDataFromCookies);
        }
    }, [loginDataFromCookies]);

    useEffect(() => {
        dispatch(resetUserAuthDetailsDispatcher);
    }, []);

    return (
        <div
            className={`${styles.sgn_body}`}
            style={{
                backgroundImage: `url(${LoginPageBackground})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: "white",
            }}
            onClick={addclass}
        >
            <div className={`${styles.container}`}>
                <div className={`${styles.title}`}>
                    <img
                        className={`${styles.roopya_logo}`}
                        style={{
                            height: "4.5vh",
                            width: "4.5wh",
                        }}
                        src={roopyaMoneyLogo}
                        alt="img"
                    />
                    <p className={`${styles.h3}`}>Sign In</p>
                </div>
                <div className={`${styles.content}`}>
                    <div className={`${styles.sign_up_form}`}>
                        <div className={`${styles.tab_content}`} id="nav-tabContent">
                            <div
                                className={`${styles.tab_pane} ${styles.fade} ${styles.show} ${styles.active}`}
                                id="nav-home"
                                role="tabpanel"
                                aria-labelledby="nav-home-tab"
                            >
                                <div className={`${styles.frm_fld}`}>
                                    <div>
                                        <div className={`${styles.lgn_stl}`}>
                                            <PersonIcon className={`${styles.icn_login}`} />

                                            <div className={`${styles.input_container}`}>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={details.email}
                                                    onChange={onSignDataChange}
                                                    placeholder={placeEmailHold}
                                                />
                                                <label
                                                    className={`${styles.label}`}
                                                    htmlFor="email"
                                                    id="label-email-Cre-Inst"
                                                >
                                                    <div className={`${styles.text}`}>Enter Your Email</div>
                                                </label>
                                            </div>
                                        </div>
                                        <FormHelperText error className={`${styles.error_cstmmsg}`}>
                                            {errorDetails?.email}
                                        </FormHelperText>
                                    </div>

                                    <div style={{ height: "50px" }}>
                                        <div>
                                            <div className={`${styles.lgn_stl}`}>
                                                <LockIcon className={`${styles.icn_login}`} />

                                                <div className={`${styles.input_container}`}>
                                                    <input
                                                        type={!isVisible ? "password" : "text"}
                                                        id="password"
                                                        name="password"
                                                        value={details.password}
                                                        onChange={onSignDataChange}
                                                        placeholder={placePassHold}
                                                    />

                                                    <label
                                                        className={`${styles.label}`}
                                                        htmlFor="password"
                                                        id="label-password-Cre-Inst"
                                                    >
                                                        <div className={`${styles.text}`}>Enter Your Password</div>
                                                    </label>
                                                </div>
                                            </div>

                                            <span className={`${styles.cstm_icon}`} onClick={toggle}>
                                                {isVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                                            </span>
                                            <FormHelperText error className={`${styles.error_cstmmsgeye}`}>
                                                {errorDetails?.password}
                                            </FormHelperText>
                                        </div>
                                    </div>

                                    <button
                                        className={`${styles.sign_up_btn}`}
                                        onClick={handleSubmit}
                                        disabled={isDisabled}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <span>Sign In</span>

                                        <div className="lo_dr" style={{ marginTop: "5px" }}>
                                            {isLoading ? <Loader /> : ""}
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className={`${styles.t_c_text}`}>
                        By clicking on “Sign In” button, you are agreeing to the{" "}
                        <a href={`https://roopya.money/terms-conditions/`} target="_blank">
                            Terms of Service
                        </a>{" "}
                        and the{" "}
                        <a href={`https://roopya.money/privacy-policy/`} target="_blank">
                            Privacy Policy
                        </a>{" "}
                        .
                    </p>

                    <p className={`${styles.already_have_account}`}>
                        Don't have an account ?<Link to="/register"> Sign Up</Link>
                    </p>

                    <div className={`${styles.forget_password_div}`}>
                        {/* <img src={forgetpassLogo} alt="forget password logo" /> */}

                        <Link to="/forget_password" className={`${styles.forget_password}`}>
                            Forget your password ?
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    workSpacetData: state.workSpacetData,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getAccessLevel,
            loginUser,
            updateAlertMsg,
            workSpace,
            workSpaceSuccess,
            gkData,
            gkDataSuccess,
            resetPassword,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SigninPage);
