import { call, put, takeLatest, all, fork } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

function searchPipelineLeadsApi(params) {
  let id = params.id;
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.SEARCH_PIPLELINE_LEADS}/${id}`,
    data: params,
  });
}

export function* searchPipelineLeadsActionEffect(searchPipelineLeadsAction) {
  const { payload, resolve, reject } = searchPipelineLeadsAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(searchPipelineLeadsApi, payload);
    yield put(toggleBackDrop(false));

    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.SEARCH_PIPELINE_LEADS_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
      return yield put({
        type: actions.SEARCH_PIPELINE_LEADS_FAILED,
        data,
      });
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
    return yield put({
      type: actions.SEARCH_PIPELINE_LEADS_FAILED,
    });
  }
}

export function* searchPipelineLeadsActionWatcher() {
  yield takeLatest(
    actions.SEARCH_PIPELINE_LEADS,
    searchPipelineLeadsActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(searchPipelineLeadsActionWatcher)]);
}
