import { nanoid } from "nanoid";

export const setCookie = (cookieName, cookieValue, expiryDays, domain = `.${process.env.REACT_APP_BASE_URL}`) => {
    if (typeof window === "undefined") return;
    let expires = "";
    if (expiryDays) {
        const d = new Date();
        d.setTime((d.getTime() + expiryDays) * (24 * 60 * 60 * 1000));
        expires = `expires=${d.toUTCString()};`;
    }
    if (window.location.host.includes("localhost")) {
        document.cookie = `${cookieName}=${cookieValue};${expires}path=/`;
    } else {
        document.cookie = `${cookieName}=${cookieValue};${expires}path=/; secure; domain=${domain}`;
    }
};

export const getCookie = (key, cookieString) => {
    if (cookieString) {
        return cookieString.replace(new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`), "$1");
    } else if (typeof window === "undefined") return "";
    return document.cookie.replace(new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`), "$1");
};

export const deleteCookie = (cname) => {
    const expires = "expires=Thu, 01-Jan-70 00:00:01 GMT;path=/";
    document.cookie = `${cname}=;${expires}`;
};

export const deleteAllCookies = (domain = window.location.host) => {
    var cookies = document.cookie.split(";");
    if (window.location.host.includes("localhost")) {
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01-Jan-70 00:00:01 GMT;path=/";
        }
    } else {
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + `=;expires=Thu, 01-Jan-70 00:00:01 GMT;path=/;domain=.${domain}`;
        }
    }
};

export function isEmpty(obj) {
    let isempty = false;
    const type = typeof obj;

    isempty = isempty || !obj;
    isempty = isempty || type === "undefined"; // if it is undefined
    isempty = isempty || obj === null; // if it is null
    isempty = isempty || (type === "string" && obj.trim() === ""); // if the string is empty
    isempty = isempty || obj === false || obj === 0; // if boolean value returns false
    isempty = isempty || (Array.isArray(obj) && obj.length === 0); // if array is empty
    isempty = isempty || (type === "object" && Object.keys(obj).length === 0); // if object is empty

    return isempty;
}

export function getCurrentYear() {
    return new Date().getFullYear();
}

export function convertJsonToFormData(data) {
    const formData = new FormData();
    const entries = Object.entries(data);
    for (let i = 0; i < entries.length; i++) {
        // don't try to be smart by replacing it with entries.each, it has drawbacks
        const arKey = entries[i][0];
        let arVal = entries[i][1];
        if (typeof arVal === "boolean") {
            arVal = arVal === true ? 1 : 0;
        }
        if (Array.isArray(arVal)) {
            // if (this.isFile(arVal[0])) {
            //   for (let z = 0; z < arVal.length; z++) {
            //     formData.append(`${arKey}[]`, arVal[z]);
            //   }

            //   continue; // we don't need to append current element now, as its elements already appended
            // }
            if (arVal[0] instanceof Object) {
                for (let j = 0; j < arVal.length; j++) {
                    if (arVal[j] instanceof Object) {
                        // if first element is not file, we know its not files array
                        for (const prop in arVal[j]) {
                            if (Object.prototype.hasOwnProperty.call(arVal[j], prop)) {
                                // do stuff
                                if (!isNaN(Date.parse(arVal[j][prop]))) {
                                    // console.log('Valid Date \n')
                                    // (new Date(fromDate)).toUTCString()
                                    formData.append(`${arKey}[${j}][${prop}]`, new Date(arVal[j][prop]));
                                } else {
                                    formData.append(`${arKey}[${j}][${prop}]`, arVal[j][prop]);
                                }
                            }
                        }
                    }
                }
                continue; // we don't need to append current element now, as its elements already appended
            } else {
                arVal = JSON.stringify(arVal);
            }
        }

        if (arVal === null) {
            continue;
        }
        formData.append(arKey, arVal);
    }
    return formData;
}
export const getUuid = (size = 21) => nanoid(size);
export const handleOnActionItemClick = (callback) => (event) => {
    event.stopPropagation();
    callback();
};
export const getRenderableRow = (row, index) => ({
    key: `row_key_${getUuid()}`,
    ...row,
    dataIndex: index,
});
export const formatCurrency = (number, hideSymbol = false) => {
    return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 0,
    }).format(!!number || number === 0 ? number : 0);
};
export const getValueOrPlaceholder = (value) => (value !== 0 ? value || "---" : 0);
export const preferredCurrencyFormat = (number, preferredCurrencyCode) => {
    return !!number || number === 0
        ? new Intl.NumberFormat("en-IN", {
              style: preferredCurrencyCode ? "currency" : undefined,
              currency: preferredCurrencyCode || undefined,
              minimumFractionDigits: 0,
          }).format(number)
        : ``;
};
export const getBreadcrumbOption = ({ to = "", label = "", isActive = false }) => {
    return {
        to,
        label,
        isActive,
    };
};

export const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const makePlural = (word) => {
    // Dictionary of irregular plural forms
    const irregularPlurals = {
        child: "children",
        person: "people",
        man: "men",
        woman: "women",
        tooth: "teeth",
        foot: "feet",
        mouse: "mice",
        goose: "geese",
        ox: "oxen",
        data: "data",
        // Add more irregular plurals as needed
    };

    if (irregularPlurals[word.split(" ")[word.split(" ").length - 1]]) {
        return word;
    }

    // Check if the word is in the irregular plurals dictionary
    if (irregularPlurals[word]) {
        return irregularPlurals[word];
    }

    // Check if the word is already plural
    if (word.endsWith("s") && !irregularPlurals[word.slice(0, -1)]) {
        return word;
    }

    // Check for common rules for creating plural forms
    if (word.endsWith("s") || word.endsWith("x") || word.endsWith("z") || word.endsWith("ch") || word.endsWith("sh")) {
        return word + "es";
    } else if (word.endsWith("y") && !["a", "e", "i", "o", "u"].includes(word[word.length - 2])) {
        // If the word ends with 'y' preceded by a consonant, replace 'y' with 'ies'
        return word.slice(0, -1) + "ies";
    } else {
        // For most cases, just add 's' at the end
        return word + "s";
    }
};

// ?Converts 'user/:user_id/group/:id/role/:roleId' => 'user/1/group/21/role/3'
export const replaceVariableWithValueInURL = (url, values = []) => {
    if (!url) {
        return "";
    }

    // regex => `:anyVar1able`
    // eslint-disable-next-line no-useless-escape
    const regex = /\:[a-zA-Z0-9\_]*/;

    let newUrl = url.concat("");

    console.log("values", values);

    for (let i = 0; i < values.length; i++) {
        const item = values[i];

        const match = regex.exec(newUrl);

        if (match === null) {
            return newUrl;
        }
        newUrl = newUrl.replace(match[match.length - 1], item || "");
    }

    return newUrl;
};
