import * as actions from "../actions/types";

const initialState = {
  secretKeyListData: [],
};

const secretkeyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SECRET_LIST_ACTION:
      return {
        ...state,
        secretKeyListData: action.data,
      };
    case actions.SECRET_LIST_ACTION_SUCCESS:
      return {
        ...state,
        secretKeyListData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default secretkeyListReducer;
