import { call, put, takeLatest, all, fork} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
// import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty} from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Update role API
function updateRoleApi(params) {
  // const domain = getCookie("domain");

  // console.log(params, domain);
  let details = params.data;
  let id = params.roleId;
  // console.log(id, details, "params id");
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.UPDATE_ROLE}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    //   // domain_name: domain,
    //   // apiKey: "D2BDF6499CD925AB4DAF513BE589F",
    //   // apiSecret: "F6D9851417DBA8492E25F6E6B43E9",
    // },
    data: details,
  });
}

// Update role action effect
export function* updateRoleActionEffect(updateRoleAction) {
  const { payload, resolve, reject } = updateRoleAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(updateRoleApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Update role action watcher
export function* updateRoleActionWatcher() {
  yield takeLatest(actions.UPDATE_ROLE, updateRoleActionEffect);
}

export default function* rootSaga() {
  yield all([fork(updateRoleActionWatcher)]);
}
