import axios from "../../axios";
import {
  ADD_ORGANIZATION_DETAILS,
  DELETE_ORGANIZATION,
  GET_ORGANIZATION_DETAILS,
} from "../../../utils/urls";

const getOrganizationDetails = async (data) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_ORGANIZATION_DETAILS}`
  );
  return res?.data;
};

const addOrganizationDetails = async (data) => {
  console.log("Payload : ", data);
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${ADD_ORGANIZATION_DETAILS}`,
    data
  );
  console.log("inside addOrganizationDetails res=>", res);
  return res;
};

const deleteOrganization = async (data) => {
  console.log("Payload : ", data);
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${DELETE_ORGANIZATION}`
  );
  console.log("inside getOrganizationDetails res=>", res);
  return res;
};

export { getOrganizationDetails, addOrganizationDetails, deleteOrganization };
