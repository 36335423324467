import * as actions from "../actions/types";

const initialState = {
  addBusiness: [],
  getBusinessData: [],
  getBusinessSuccess: [],
  getBusinessErr: [],
  getBusinessRuleList: []
};

const businessDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ADD_BUSINESS_RULE:
      return {
        ...state,
        addBusiness: action.data,
        // allUsers: state.allUsers.map(allUser => allUser.data.id === action.data.data.id)
      };
    case actions.ADD_BUSINESS_RULE_SUCCESS:
      return {
        ...state,
        addBusiness: action.data,
        // allUsers: state.allUsers.map(allUser => allUser.data.id === action.data.data.id)
      };
    case actions.ADD_BUSINESS_RULE_ERROR:
      return {
        ...state,
        addBusiness: action.data,
        // allUsers: state.allUsers.map(allUser => allUser.data.id === action.data.data.id)
      };

    case actions.GET_BUSINESS_DATA:
      return {
        ...state,
        getBusinessData: action.data,
      };
    case actions.GET_BUSINESS_SUCCESS_DATA:
      return {
        ...state,
        getBusinessData: action.data,
      }
    case actions.GET_BUSINESS_ERROR_DATA:
      return {
        ...state,
        getBusinessData: action.data,
      }
    case actions.GET_BUSINESS_ERROR_DATA:
      return {
        ...state,
        getBusinessData: action.data,
      }
    case actions.GET_BUSINESS_RULE_LIST:
      return {
        ...state,
        getBusinessRuleList: action.data,
      };
    case actions.GET_BUSINESS_RULE_LIST_SUCCESS:
      return {
        ...state,
        getBusinessRuleList: action.data,
      };
    case actions.GET_BUSINESS_RULE_LIST_ERROR:
      return {
        ...state,
        getBusinessRuleList: action.data,
      };
    default: {
      return state;
    }
  }
};
export default businessDataReducer;
