export const LOGIN_ACTION = "LOGIN_ACTION";

export const REGISTER_ACTION = "REGISTER_ACTION";
export const TASK_LIST_ACTION = "TASK_LIST_ACTION";
export const TASK_LIST_ACTION_SUCCESS = "TASK_LIST_ACTION_SUCCESS";
export const ADD_TASK = "ADD_TASK";
export const GET_BUSINESS_RULE_EDIT = "GET_BUSINESS_RULE_EDIT";
export const GET_BUSINESS_RULE_EDIT_SUCCESS = "GET_BUSINESS_RULE_EDIT_SUCCESS";
export const GET_BUSINESS_RULE_EDIT_ERROR = "GET_BUSINESS_RULE_EDIT_ERROR";
export const USERS_LIST_ACTION = "USERS_LIST_ACTION";
export const USERS_LIST_PARTNER_ACTION = "USERS_LIST_PARTNER_ACTION";
export const USERS_LIST_ACTIONN = "USERS_LIST_ACTIONN";
export const USERS_LIST_ACTION_SUCCESS = "USERS_LIST_ACTION_SUCCESS";
export const USERS_LIST_PARTNER_SUCCESS = "USERS_LIST_PARTNER_SUCCESS";
export const DELETE_TASK = "DELETE_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const ADD_MEMBER = "ADD_MEMBER";
export const BANK_LIST_ACTION = "BANK_LIST_ACTION";
export const BANK_LIST_ACTION_SUCCESS = "BANK_LIST_ACTION_SUCCESS";
export const ACTIVE_BANK_LIST_ACTION = "ACTIVE_BANK_LIST_ACTION";
export const ACTIVE_BANK_LIST_ACTION_SUCCESS = "ACTIVE_BANK_LIST_ACTION_SUCCESS";
export const ACTIVE_BANK_LIST_LEADS_ACTION = "ACTIVE_BANK_LIST_LEADS_ACTION";
export const ACTIVE_BANK_LIST_LEADS_ACTION_SUCCESS = "ACTIVE_BANK_LIST_LEADS_ACTION_SUCCESS";
export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";
export const TASK_DETAILS = "TASK_DETAILS";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const OPEN_BACKDROP = "OPEN_BACKDROP";
export const ALERT_MSG = "ALERT_MSG";
export const UTM_SOURCE_BY_SECRETKEY = "UTM_SOURCE_BY_SECRETKEY";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const ASSIGN_TASK_LIST_ACTION = "ASSIGN_TASK_LIST_ACTION";
export const ASSIGN_TASK_LIST_ACTION_SUCCESS = "ASSIGN_TASK_LIST_ACTION_SUCCESS";

export const CREATE_PASSWORD = "CREATE_PASSWORD";
export const USER_DETAILS = "USER_DETAILS";
export const USER_ROLES = "USER_ROLES";
export const USER_AUTH_DETAILS = "USER_AUTH_DETAILS";
export const SET_ORGANIZATION_LOGO = "SET_ORGANIZATION_LOGO";
export const RESET_USER_AUTH_DETAILS = "RESET_USER_AUTH_DETAILS";
export const ADD_ROLE = "ADD_ROLE";
export const ROLE_LIST_ACTION = "ROLE_LIST_ACTION";
export const ROLE_LIST_ACTION_SUCCESS = "ROLE_LIST_ACTION_SUCCESS";
export const STATUS_LIST_ACTION = "STATUS_LIST_ACTION";
export const STATUS_LIST_ACTION_SUCCESS = "STATUS_LIST_ACTION_SUCCESS";
export const SET_PAN_CARD = 'SET_PAN_CARD';
export const SET_AADHAAR_CARD = 'SET_AADHAAR_CARD';

export const PRIORITY_LIST_ACTION = "PRIORITY_LIST_ACTION";
export const PRIORITY_LIST_ACTION_SUCCESS = "PRIORITY_LIST_ACTION_SUCCESS";
export const TASK_COMMENTS = "TASK_COMMENTS";
export const TASK_COMMENTS_SUCCESS = "TASK_COMMENTS_SUCCESS";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const ORGANISATION_DETAILS_ACTION = "ORGANISATION_DETAILS_ACTION";
export const ORGANISATION_DETAILS_ACTION_SUCCESS = "ORGANISATION_DETAILS_ACTION_SUCCESS";
export const TRELLO_LIST_ACTION = "TRELLO_LIST_ACTION";
// export const TRELLO_LIST_ACTION_SUCCESS = "TRELLO_LIST_ACTION_SUCCESS";
export const EMAIL_VERIFY = "EMAIL_VERIFY";
export const WORK_SPACE_ACTION = "WORK_SPACE_ACTION";
export const WORK_SPACE_ACTION_SUCCESS = "WORK_SPACE_ACTION_SUCCESS";
export const GENERAL_DATA_ACTION = "GENERAL_DATA_ACTION";
export const GENERAL_DATA_ACTION_SUCCESS = "GENERAL_DATA_ACTION_SUCCESS";
export const STAGE_DATA_ACTION = "STAGE_DATA_ACTION";
export const STAGE_DATA_ACTION_SUCCESS = "STAGE_DATA_ACTION_SUCCESS";
export const DELETE_ROLE = "DELETE_ROLE";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const ACCESS_DATA_ACTION = "ACCESS_DATA_ACTION";
export const ACCESS_DATA_ACTION_SUCCESS = "ACCESS_DATA_ACTION_SUCCESS";
export const ACCESS_LEVEL_ACTION_BY_ROLE = "ACCESS_LEVEL_ACTION_BY_ROLE";
export const ACCESS_LEVEL_ACTION_BY_ROLE_SUCCESS = "ACCESS_LEVEL_ACTION_BY_ROLE_SUCCESS";
export const UPDATE_ACCESS_LEVEL_ACTION_BY_ROLE = "UPDATE_ACCESS_LEVEL_ACTION_BY_ROLE";
export const UPDATE_ACCESS_LEVEL_ACTION_BY_ROLE_SUCCESS = "UPDATE_ACCESS_LEVEL_ACTION_BY_ROLE_SUCCESS";
export const USER_PROFILE_ACTION = "USER_PROFILE_ACTION";
export const USER_PROFILE_ACTION_SUCCESS = "USER_PROFILE_ACTION_SUCCESS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const DELETE_EMPLOYMENT_LOCATION = "DELETE_EMPLOYMENT_LOCATION";
export const DELETE_EMPLOYMENT_EDUCATION = "DELETE_EMPLOYMENT_EDUCATION";
export const UPDATE_ORGANISATION = "UPDATE_ORGANISATION";
export const TAGS_SEARCH = "TAGS_SEARCH";
export const LOGOUT_ACTION = "LOGOUT_ACTION";
// export const LOGOUT_ACTION_SUCCESS = "LOGOUT_ACTION_SUCCESS";
export const DELETE_TASKMEMBER = "DELETE_TASKMEMBER";
export const DELETE_TASK_DOCUMENT = "DELETE_TASK_DOCUMENT";
export const DELETE_USER_DOCUMENT = "DELETE_USER_DOCUMENT";
export const ADD_TASK_MEMBER = "ADD_TASK_MEMBER";
export const PIPELINE_ACTION = "PIPELINE_ACTION";

export const PIPELINE_ACTION_SUCCESS = "PIPELINE_ACTION_SUCCESS";
export const PIPELINE_ACTION_ERROR = "PIPELINE_ACTION_ERROR";
export const LEADS_LIST = "LEADS_LIST";
export const PIPELINE_LEADS = "PIPELINE_LEADS";
export const PIPELINE_LEADS_SUCCESS = "PIPELINE_LEADS_SUCCESS";
export const PIPELINE_LEADS_FAILED = "PIPELINE_LEADS_FAILED";

export const ADD_PIPELINE = "ADD_PIPELINE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const RESETPASSWORD_ACTION = "RESETPASSWORD_ACTION";
export const DELETE_TAGS = "DELETE_TAGS";
export const UPDATE_TASK_DOCUMENT = "UPDATE_TASK_DOCUMENT";
export const LENDERS_ACTION = "LENDERS_ACTION";
export const LENDERS_ACTION_SUCCESS = "LENDERS_ACTION_SUCCESS";
export const DELETE_PIPELINE = "DELETE_PIPELINE";
export const UPDATE_PIPELINE = "UPDATE_PIPELINE";
export const LAST_VIEW_DOCUMENT = "LAST_VIEW_DOCUMENT";
export const PIPELINE_SHARE = "PIPELINE_SHARE";
export const ADD_PIPELINE_CRITERIA = "ADD_PIPELINE_CRITERIA";
export const CITY_LIST_ACTION = "CITY_LIST_ACTION";
export const CITY_LIST_ACTION_SUCCESS = "CITY_LIST_ACTION_SUCCESS";

export const APP_NEW_APIKEY = "APP_NEW_APIKEY";
export const SECRET_LIST_ACTION = "SECRET_LIST_ACTION";
export const SECRET_LIST_ACTION_SUCCESS = "SECRET_LIST_ACTION_SUCCESS";

export const PIPELINE_PARTNER_ASSIGN = "PIPELINE_PARTNER_ASSIGN";

export const ADD_LEAD_SOURCE = "ADD_LEAD_SOURCE";
export const UPDATE_SECRET_KEY = "UPDATE_SECRET_KEY";
export const DELETE_SECRET_KEY = "DELETE_SECRET_KEY";

export const PIPELINE_PARTNER_LIST = "PIPELINE_PARTNER_LIST";
export const PIPELINE_PARTNER_LIST_SUCCESS = "PIPELINE_PARTNER_LIST_SUCCESS";
export const DELETE_PARTNER = "DELETE_PARTNER";
export const ADD_PIPELINE_LOGO = "ADD_PIPELINE_LOGO";
export const PIPELINE_DETAILS = "PIPELINE_DETAILS";
export const LOGO_LIST_ACTION = "LOGO_LIST_ACTION";
export const LOGO_LIST_ACTION_SUCCESS = "LOGO_LIST_ACTION_SUCCESS";
export const DELETE_PIPELINE_LOGO = "DELETE_PIPELINE_LOGO";
export const UPDATE_PIPLEINE_LOGO = "UPDATE_PIPLEINE_LOGO";
export const DASHBOARD_DATA_ACTION = "DASHBOARD_DATA_ACTION";
export const DASHBOARD_DATA_ACTION_SUCCESS = "DASHBOARD_DATA_ACTION_SUCCESS";
export const DASHBOARD_DATA_DATE = "DASHBOARD_DATA_DATE";
export const LEADS_DETAILS = "LEADS_DETAILS";
export const UPDATE_LEADS = "UPDATE_LEADS";
export const CUSTOMER_ACTION = "CUSTOMER_ACTION";
export const CUSTOMER_BRE_ACTION = "CUSTOMER_BRE_ACTION";
export const CUSTOMER_ACTION_SUCCESS = "CUSTOMER_ACTION_SUCCESS";
export const CUSTOMER_BRE_ACTION_SUCCESS = "CUSTOMER_BRE_ACTION_SUCCESS";
export const CUSTOMER_DETAIL_ACTION = "CUSTOMER_DETAIL_ACTION";
export const CUSTOMER_DETAIL_ACTION_SUCCESS = "CUSTOMER_DETAIL_ACTION_SUCCESS";
export const PIPELINE_ALL_USER = "PIPELINE_ALL_USER";
export const PIPELINE_ADD_USER = "PIPELINE_ADD_USER";
export const PIPELINE_ADD_USER_SUCCESS = "PIPELINE_ADD_USER_SUCCESS";
export const PIPELINE_ADD_USER_ERROR = "PIPELINE_ADD_USER_ERROR";
export const PIPELINE_SINGLE_USER = "PIPELINE_SINGLE_USER";
export const PIPELINE_DELETE_USER = "PIPELINE_DELETE_USER";
export const UPLOAD_EXCEL_SUBMIT = "UPLOAD_EXCEL_SUBMIT";
export const ADD_STAGE_TEMPLATE = "ADD_STAGE_TEMPLATE";
export const GET_STAGE_TEMPLATE = "GET_STAGE_TEMPLATE";
export const GET_ALL_STAGE_TEMPLATE = "GET_TOTAL_STAGE_TEMPLATE";
export const GET_SINGLE_STAGE_TEMPLATE = "GET_SINGLE_STAGE_TEMPLATE";
export const EDIT_STAGE_TEMPLATE = "EDIT_STAGE_TEMPLATE";
export const DELETE_STAGE_TEMPLATE = "DELETE_STAGE_TEMPLATE";
export const ADD_BUSINESS_RULE = "ADD_BUSINESS_RULE";
export const ADD_BUSINESS_RULE_SUCCESS = "ADD_BUSINESS_SUCCESS";
export const ADD_BUSINESS_RULE_ERROR = "ADD_BUSINESS_RULE_ERROR";
export const GET_BUSINESS_DATA = "GET_BUSINESS_DATA";
export const GET_BUSINESS_SUCCESS_DATA = "GET_BUSINESS_SUCCESS_DATA";
export const GET_BUSINESS_ERROR_DATA = "GET_BUSINESS_ERROR_DATA";
export const DYNAMIC_APPLICATION_DASHBOARD = "DYNAMIC_APPLICATION_DASHBOARD";
export const DYNAMIC_APPLICATION_DASHBOARD_SUCCESS = "DYNAMIC_APPLICATION_DASHBOARD_SUCCESS";
export const DYNAMIC_APPLICATION_DASHBOARD_ERROR = "DYNAMIC_APPLICATION_DASHBOARD_ERROR";
export const DYNAMIC_LENDER_DASHBOARD = "DYNAMIC_LEADS_DASHBOARD";
export const DYNAMIC_LENDER_DASHBOARD_SUCCESS = "DYNAMIC_LEADS_DASHBOARD_SUCCESS";
export const DYNAMIC_LENDER_DASHBOARD_ERROR = "DYNAMIC_LEADS_DASHBOARD_ERROR";
export const DYNAMIC_PARTNER_DASHBOARD = "DYNAMIC_PARTNER_DASHBOARD";
export const DYNAMIC_PARTNER_DASHBOARD_SUCCESS = "DYNAMIC_PARTNER_DASHBOARD_SUCCESS";
export const DYNAMIC_PARTNER_DASHBOARD_ERROR = "DYNAMIC_PARTNER_DASHBOARD_ERROR";
export const GET_BUSINESS_RULE_LIST = "GET_BUSINESS_RULE_LIST";
export const GET_BUSINESS_RULE_LIST_SUCCESS = "GET_BUSINESS_RULE_LIST_SUCCESS";
export const GET_BUSINESS_RULE_LIST_ERROR = "GET_BUSINESS_RULE_LIST_ERROR";
export const DASHBOARD_LENDER_DETAILS = "DASHBOARD_LENDER_DETAILS";
export const DASHBOARD_LENDER_DETAILS_SUCCESS = "DASHBOARD_LENDER_DETAILS_SUCCESS";
export const DASHBOARD_LENDER_DETAILS_ERROR = "DASHBOARD_LENDER_DETAILS_ERROR";
export const MARKET_PLACE_DOMAIN = "MARKET_PLACE_DOMAIN";
export const MARKET_PLACE_DOMAIN_SUCCESS = "MARKET_PLACE_DOMAIN_SUCCESS";
export const MARKET_PLACE_DOMAIN_ERROR = "MARKET_PLACE_DOMAIN_ERROR";
export const MARKET_PLACE_SHARE = "MARKET_PLACE_SHARE";
export const MARKET_PLACE_SHARE_SUCCESS = "MARKET_PLACE_SHARE_SUCCESS";
export const MARKET_PLACE_SHARE_ERROR = "MARKET_PLACE_SHARE_ERROR";
export const MARKET_PLACE_DOMAIN_LIST = "MARKET_PLACE_DOMAIN_LIST";
export const MARKET_PLACE_DOMAIN_LIST_SUCCESS = "MARKET_PLACE_DOMAIN_LIST_SUCCESS";
export const MARKET_PLACE_DOMAIN_LIST_ERROR = "MARKET_PLACE_DOMAIN_LIST_ERROR";
export const SINGLE_LEAD_ELIGIBILITY = "SINGLE_LEAD_ELIGIBILITY";
export const SINGLE_LEAD_ELIGIBILITY_SUCCESS = "SINGLE_LEAD_ELIGIBILITY_SUCCESS";
export const SINGLE_LEAD_ELIGIBILITY_ERROR = "SINGLE_LEAD_ELIGIBILITY_ERROR";
export const AUTO_LOGIN_LIST = "AUTO_LOGIN_LIST";
export const AUTO_LOGIN_LIST_SUCCESS = "AUTO_LOGIN_LIST_SUCCESS";
export const AUTO_LOGIN_LIST_ERROR = "AUTO_LOGIN_LIST_ERROR";
export const ALL_BANK_CREDENTIAL_LIST = "ALL_BANK_CREDENTIAL_LIST";
export const ALL_BANK_CREDENTIAL_LIST_SUCCESS = "ALL_BANK_CREDENTIAL_LIST_SUCCESS";
export const ALL_BANK_CREDENTIAL_LIST_ERROR = "ALL_BANK_CREDENTIAL_LIST_ERROR";
export const ADD_BANK_AUTO_LOGIN = "ADD_BANK_AUTO_LOGIN";
export const ADD_BANK_AUTO_LOGIN_SUCCESS = "ADD_BANK_AUTO_LOGIN_SUCCESS";
export const ADD_BANK_AUTO_LOGIN_ERROR = "ADD_BANK_AUTO_LOGIN_ERROR";
export const AUTO_LOGIN_ENABLE_DISABLE = "AUTO_LOGIN_ENABLE_DISABLE";
export const AUTO_LOGIN_ENABLE_DISABLE_SUCCESS = "AUTO_LOGIN_ENABLE_DISABLE_SUCCESS";
export const AUTO_LOGIN_ENABLE_DISABLE_ERROR = "AUTO_LOGIN_ENABLE_DISABLE_ERROR";
export const TIMELINE_DATA = "TIMELINE_DATA";
export const TIMELINE_DATA_SUCCESS = "TIMELINE_DATA_SUCCESS";
export const TIMELINE_DATA_ERROR = "TIMELINE_DATA_ERROR";
export const NOTES_DATA_LIST = "NOTES_DATA_LIST";
export const NOTES_DATA_LIST_SUCCESS = "NOTES_DATA_LIST_SUCCESS";
export const NOTES_DATA_LIST_ERROR = "NOTES_DATA_LIST_ERROR";
export const ADD_GLOBAL_DATA_NOTES = "ADD_GLOBAL_DATA_NOTES";
export const ADD_GLOBAL_DATA_NOTES_SUCCESS = "ADD_GLOBAL_DATA_NOTES_SUCCESS";
export const ADD_GLOBAL_DATA_NOTES_ERROR = "ADD_GLOBAL_DATA_NOTES_ERROR";
export const AUTO_LOGIN_REFRESH = "AUTO_LOGIN_REFRESH";
export const AUTO_LOGIN_REFRESH_SUCCESS = "AUTO_LOGIN_REFRESH_SUCCESS";
export const AUTO_LOGIN_REFRESH_ERROR = "AUTO_LOGIN_REFRESH_ERROR";
export const CRON_GET_STATUS = "CRON_GET_STATUS";
export const CRON_GET_STATUS_SUCCESS = "CRON_GET_STATUS_SUCCESS";
export const CRON_GET_STATUS_ERROR = "CRON_GET_STATUS_ERROR";
export const CRON_ADD_STATUS = "CRON_ADD_STATUS";
export const CRON_ADD_STATUS_SUCCESS = "CRON_ADD_STATUS_SUCCESS";
export const CRON_ADD_STATUS_ERROR = "CRON_ADD_STATUS_ERROR";
export const CRON_UPDATE_STATUS = "CRON_UPDATE_STATUS";
export const CRON_UPDATE_STATUS_SUCCESS = "CRON_UPDATE_STATUS_SUCCESS";
export const CRON_UPDATE_STATUS_ERROR = "CRON_UPDATE_STATUS_ERROR";
export const PIPELINE_ARCHIVE_DELETE = "PIPELINE_ARCHIVE_DELETE";
export const PIPELINE_ARCHIVE_DELETE_SUCCESS = "PIPELINE_ARCHIVE_DELETE_SUCCESS";
export const PIPELINE_ARCHIVE_DELETE_ERROR = "PIPELINE_ARCHIVE_DELETE_ERROR";

export const NEXT_STAGE = "NEXT_STAGE";
export const NEXT_STAGE_SUCCESS = "NEXT_STAGE_SUCCESS";
export const NEXT_STAGE_ERROR = "NEXT_STAGE_ERROR";

export const LEAD_STAGE_LIST = "LEAD_STAGE_LIST";
export const LEAD_STAGE_LIST_SUCCESS = "LEAD_STAGE_LIST_SUCCESS";
export const LEAD_STAGE_LIST_ERROR = "LEAD_STAGE_LIST_ERROR";

export const SEARCH_PIPELINE_LEADS = "SEARCH_PIPELINE_LEADS";
export const SEARCH_PIPELINE_LEADS_SUCCESS = "SEARCH_PIPELINE_LEADS_SUCCESS";
export const SEARCH_PIPELINE_LEADS_FAILED = "SEARCH_PIPELINE_LEADS_FAILED";

export const SEARCH_LEADS_BY_DATE = "SEARCH_LEADS_BY_DATE";
export const SEARCH_LEADS_BY_DATE_SUCCESS = "SEARCH_LEADS_BY_DATE_SUCCESS";
export const SEARCH_LEADS_BY_DATE_FAILED = "SEARCH_LEADS_BY_DATE_FAILED";

export const MULTI_FILTER_LEAD_LIST = "MULTI_FILTER_LEAD_LIST";
export const UPDATE_LEAD_DATA = "UPDATE_LEAD_DATA";
export const MULTI_FILTER_LEAD_LIST_SUCCESS = "MULTI_FILTER_LEAD_LIST_SUCCESS";
export const MULTI_FILTER_LEAD_LIST_ERROR = "MULTI_FILTER_LEAD_LIST_ERROR";
export const REFRESH_EMPLOYEE_DETAILS = "REFRESH_EMPLOYEE_DETAILS";
