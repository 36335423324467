import React from "react";
import { Snackbar, Button } from "@material-ui/core";
import { GetAppOutlined } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import { isEmpty } from "../../utils/common";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomSnackbar(props) {
  const {
    message = "",
    open,
    messageType = "",
    handleAlertAction,
    action = {},
  } = props;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={4000}
      onClose={() => props.closeNotification()}
    >
      <Alert
        onClose={() => props.closeNotification()}
        severity={messageType}
        style={{ fontSize: 12 }}
        action={
          !isEmpty(action) ? (
            <Button
              color="inherit"
              size="small"
              startIcon={<GetAppOutlined />}
              onClick={() => handleAlertAction(action.url)}
            >
              Download
            </Button>
          ) : null
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
CustomSnackbar.defaultProps = {
  messageType: "info",
  open: false,
};
