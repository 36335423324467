import * as actions from "../actions/types";

const initialState = {
    globalNotesDataList: [],
};

const globalNotesDataListReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.NOTES_DATA_LIST:
            return {
                ...state,
                globalNotesDataList: action.data,
            };
        case actions.NOTES_DATA_LIST_SUCCESS:
            return {
                ...state,
                globalNotesDataList: action.data,
            };
        case actions.NOTES_DATA_LIST_ERROR:
            return {
                ...state,
                globalNotesDataList: action.data,
            };
        default: {
            return state;
        }
    }
};
export default globalNotesDataListReducer;
