import * as actions from "../actions/types";

const initialState = {
  assigntaskListData: [],
};

const assigntasklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ASSIGN_TASK_LIST_ACTION:
      return {
        ...state,
        assigntaskListData: action.data,
      };
    case actions.ASSIGN_TASK_LIST_ACTION_SUCCESS:
      return {
        ...state,
        assigntaskListData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default assigntasklistReducer;
