import * as actions from "../actions/types";

const initialState = {
  activeBankListLeadsData: [],
};

const activeBanklistLeadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ACTIVE_BANK_LIST_LEADS_ACTION:
      return {
        ...state,
        activeBankListLeadsData: action.data,
      };
    case actions.ACTIVE_BANK_LIST_LEADS_ACTION_SUCCESS:
      return {
        ...state,
        activeBankListLeadsData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default activeBanklistLeadsReducer;
