import AddLeadSourceSaga from "./addLeadSourceSaga";
import AddMemberSaga from "./addMemberSaga";
import AddNewApiKeySaga from "./addNewApiKeySaga";
import AddPipelineCriteriaSaga from "./addPipelineCriteriaSaga";
import AddPipelineLogoSaga from "./addPipelineLogoSaga";
import AddPipelineSaga from "./addPipelineSaga";
import AddRoleSaga from "./addRoleSaga";
import AddTaskSaga from "./addTaskSaga";
import AssigntaskSaga from "./assignTaskSaga";
import CityListSaga from "./cityListSaga";
import CreatePasswordSaga from "./createPasswordSaga";
import DeleteMemberSaga from "./deleteMemberSaga";
import DeletePipelineLogoSaga from "./deletePipelineLogoSaga";
import DeletePipelinePartnerSaga from "./deletePipelinePartnerSaga";
import DeleteSecretKeySaga from "./deleteSecretKeySaga";
import DeleteTagsSaga from "./deleteTagsSaga";
import DeleteTaskSaga from "./deleteTaskSaga";
import ForgotpasswordSaga from "./forgotpasswordSaga";
import GetPipelineSaga from "./getPipelineSaga";
import GetRoleSaga from "./getRoleSaga";
import GetUtmSourceBySecretKeySaga from "./getUtmSourceBySecretKeySaga";
import GetleadsByPipelineSaga from "./getleadsByPipelineSaga";
import LoginSaga from "./loginSaga";
import OrganisationDetailsSaga from "./organisationDetailsSaga";
import PipelineDetailsSaga from "./pipelineDetailsSaga";
import PipelineLogoListSaga from "./pipelineLogoListSaga";
import PriorityListSaga from "./priorityListSaga";
import RegisterSaga from "./registerSaga";
import ResetPasswordSaga from "./resetPasswordSaga";
import SecretKeyListSaga from "./secretKeyListSaga";
import StatusListSaga from "./statusListSaga";
import TaskCommentSaga from "./taskCommentSaga";
import TaskDetailsStatusSaga from "./taskDetailsSaga";
import TaskListSaga from "./taskListSaga";
import TrelloBoardListSaga from "./trelloBoardListSaga";
import UpdatePipelineLogoSaga from "./updatePipelineLogoSaga";
import UpdateRoleSaga from "./updateRoleSaga";
import UpdateSecretKeySaga from "./updateSecretKeySaga";
import UpdateTaskDocumentSaga from "./updateTaskDocumentSaga";
import UpdateTaskSaga from "./updateTaskSaga";
import UpdateTaskStatusSaga from "./updateTaskStatusSaga";
import UserDetailsSaga from "./userDetailsSaga";
import UserListSaga from "./userListSaga";
import VerifyEmail from "./verifyEmailSaga";
import WorkSpaceSaga from "./workSpaceSaga";
import addTaskMemberSaga from "./addTaskMemberSaga";
import { all } from "redux-saga/effects";
import changePasswordSaga from "./changePasswordSaga";
import deleteEmpLocationSaga from "./deleteEmpLocationSaga";
import deletePipelineSaga from "./deletePipelineSaga";
import deleteRoleSaga from "./deleteRoleSaga";
import deleteTaskDocumentsaga from "./deleteTaskDocumentsaga";
import deleteTaskMemberSaga from "./deleteTaskMemberSaga";
import deleteUserDocumentsaga from "./deleteUserDocumentsaga";
import deleteUserEducationsaga from "./deleteUserEducationsaga";
import getAccessLevelRoleSaga from "./getAccessLevelRoleSaga";
import getAccessLevelSaga from "./getAccessLevelSaga";
import getLendersFieldsSaga from "./getLendersFieldsSaga";
import getPipelineLeadsSaga from "./getPipelineLeadsSaga";
import getStageSaga from "./getStageSaga";
import getUserProfileSaga from "./getUserProfileSaga";
import getleadsByPipelineSaga from "./getleadsByPipelineSaga";
import gkDataSaga from "./gkDataSaga";
import lastViewDocumentSaga from "./lastViewDocumentSaga";
import logoutSaga from "./logoutSaga";
import pipelinePartnerAssignSaga from "./pipelinePartnerAssignSaga";
import pipelinePartnerListSaga from "./pipelinePartnerListSaga";
import pipelineShareSaga from "./pipelineShareSaga";
import tagSearchSaga from "./tagSearchSaga";
import updateAccessLevelRoleSaga from "./updateAccessLevelRoleSaga";
import updateMemberSaga from "./updateMemberSaga";
import updateOrganisationSaga from "./updateOrganisationSaga";
import updatePipelineSaga from "./updatePipelineSaga";
import updateUserProfileSaga from "./updateUserProfileSaga";
import DashboardDataSaga from "./dashboardDataSaga";
import DashboardDataByDateSaga from "./dashboardDataByDateSaga";
import LeadsDetailsSaga from "./leadsDetailsSaga";
import UpdateLeadsSaga from "./updateLeadsSaga";
import customerListSaga from "./customerListSaga";
import customerBREListSaga from "./customerBREListSaga";
import allPipeLineUserListSaga from "./allpipeLineUserListSaga";
import addPipeLineUserSaga from "./addPipeLineUserSaga";
import deletePipeLineUserSaga from "./deletePipeLineUserSaga";
import UploadExcelSaga from "./UploadExcelSaga";
import addStageTemplate from "./addStageTemplateSaga";
import banklistSaga from "./bankListSaga";
import activeBankListSaga from "./activeBankListSaga";
import activeBankListLeadsSaga from "./activeBankListLeadsSaga";
import getAllStageSaga from "./getAllStageSaga";
import getSingleStageSaga from "./getSingleStageSaga";
import editStageTemplate from "./editStageTemplate";
import deleteStageTemplate from "./deleteStageTemplate";
import getBusinessData from "./getBusinessDataSaga";
import userListPartnerSaga from "./userListPartnerSaga";
import customerDetailListSaga from "./customerDetailListSaga";
import getBusinessEditRuleSaga from "./getBusinessEditRuleSaga";
import dynamicApplicationDashBoardSaga from "./dynamicApplicationDashBoardSaga";
import dynamicLeadDashBoardSaga from "./dynamicLeadDashBoardSaga";
import dynamicPartnerDashBoardSaga from "./dynamicPartnerDashBoardSaga";
import addBusinessRuleSaga from "./addBusinessRuleSaga";
import getBusinessRuleListSaga from "./getBusinessRuleListSaga";
import dashboardLenderDetailsSaga from "./dashboardLenderDetailsSaga";
import marketPlaceDomainSaga from "./marketPlaceDomainSaga";
import saveMarketPlaceDomainSaga from "./saveMarketPlaceDomainSaga";
import marketPlaceDomainListSaga from "./marketPlaceDomainListSaga";
import singleLeadEligibilitySaga from "./singleLeadEligibilitySaga";
import autoLoginListSaga from "./autoLoginListSaga";
import allBankCredentialsListSaga from "./allBankCredentialListSaga";
import addBankAutoLoginSaga from "./addBankAutoLoginSaga";
import enableDisableAutoLoginSaga from "./enableDisableAutoLoginSaga";
import timeLineDataSaga from "./timeLineDataSaga";
import globalNotesDataListSaga from "./globalNotesDataListSaga";
import addGlobalDataNotesSaga from "./addGlobalDataNotesSaga";
import autoLoginCronSaga from "./autoLoginCronSaga";
import cronAddStatusSaga from "./cronAddStatusSaga";
import cronUpdateStatusSaga from "./cronUpdateStatusSaga";
import cronGetStatusSaga from "./cronGetStatusSaga";
import pipelineArchiveDeleteSaga from "./pipelineArchiveDeleteSaga";
import nextStageSaga from "./nextStageSaga";
import leadStageListSaga from "./leadStageListSaga";
import searchPipelineLeadsSaga from "./searchPipelineLeadsSaga";
import searchLeadsByDateSaga from "./searchLeadsByDateSaga";
import multiFilterLeadListSaga from "./multiFilterLeadListSaga";
import updateLeadDataSaga from "./updateLeadDataSaga";
export default function* rootSaga() {
  yield all([
    UpdateLeadsSaga(),
    customerListSaga(),
    customerBREListSaga(),
    LeadsDetailsSaga(),
    activeBankListLeadsSaga(),
    banklistSaga(),
    activeBankListSaga(),
    DashboardDataByDateSaga(),
    DashboardDataSaga(),
    getBusinessEditRuleSaga(),
    UpdatePipelineLogoSaga(),
    DeletePipelineLogoSaga(),
    PipelineLogoListSaga(),
    PipelineDetailsSaga(),
    AddPipelineLogoSaga(),
    DeletePipelinePartnerSaga(),
    DeleteSecretKeySaga(),
    UpdateSecretKeySaga(),
    AddLeadSourceSaga(),
    CityListSaga(),
    SecretKeyListSaga(),
    AddPipelineCriteriaSaga(),
    pipelineShareSaga(),
    getLendersFieldsSaga(),
    deletePipelineSaga(),
    UpdateRoleSaga(),
    AddPipelineSaga(),
    GetleadsByPipelineSaga(),
    GetPipelineSaga(),
    updateOrganisationSaga(),
    LoginSaga(),
    GetUtmSourceBySecretKeySaga(),
    RegisterSaga(),
    TaskListSaga(),
    AddTaskSaga(),
    UserListSaga(),
    DeleteTaskSaga(),
    UpdateTaskSaga(),
    AddMemberSaga(),
    UpdateTaskStatusSaga(),
    TaskDetailsStatusSaga(),
    DeleteMemberSaga(),
    ForgotpasswordSaga(),
    AssigntaskSaga(),
    CreatePasswordSaga(),
    AddRoleSaga(),
    GetRoleSaga(),
    StatusListSaga(),
    PriorityListSaga(),
    UserDetailsSaga(),
    TaskCommentSaga(),
    OrganisationDetailsSaga(),
    TrelloBoardListSaga(),
    VerifyEmail(),
    WorkSpaceSaga(),
    gkDataSaga(),
    getStageSaga(),
    deleteRoleSaga(),
    updateMemberSaga(),
    getAccessLevelSaga(),
    getUserProfileSaga(),
    getAccessLevelRoleSaga(),
    updateAccessLevelRoleSaga(),
    updateUserProfileSaga(),
    updateLeadDataSaga(),
    deleteEmpLocationSaga(),
    deleteUserEducationsaga(),
    tagSearchSaga(),
    logoutSaga(),
    deleteTaskMemberSaga(),
    deleteTaskDocumentsaga(),
    addTaskMemberSaga(),
    changePasswordSaga(),
    ResetPasswordSaga(),
    deleteUserDocumentsaga(),
    DeleteTagsSaga(),
    UpdateTaskDocumentSaga(),
    updatePipelineSaga(),
    lastViewDocumentSaga(),
    AddNewApiKeySaga(),
    pipelinePartnerAssignSaga(),
    pipelinePartnerListSaga(),
    getPipelineLeadsSaga(),
    allPipeLineUserListSaga(),
    addPipeLineUserSaga(),
    deletePipeLineUserSaga(),
    UploadExcelSaga(),
    addStageTemplate(),
    getAllStageSaga(),
    getSingleStageSaga(),
    editStageTemplate(),
    deleteStageTemplate(),
    getBusinessData(),
    userListPartnerSaga(),
    customerDetailListSaga(),
    dynamicApplicationDashBoardSaga(),
    dynamicLeadDashBoardSaga(),
    dynamicPartnerDashBoardSaga(),
    addBusinessRuleSaga(),
    getBusinessRuleListSaga(),
    dashboardLenderDetailsSaga(),
    marketPlaceDomainSaga(),
    saveMarketPlaceDomainSaga(),
    marketPlaceDomainListSaga(),
    singleLeadEligibilitySaga(),
    autoLoginListSaga(),
    allBankCredentialsListSaga(),
    addBankAutoLoginSaga(),
    enableDisableAutoLoginSaga(),
    timeLineDataSaga(),
    globalNotesDataListSaga(),
    addGlobalDataNotesSaga(),
    autoLoginCronSaga(),
    cronAddStatusSaga(),
    cronGetStatusSaga(),
    cronUpdateStatusSaga(),
    pipelineArchiveDeleteSaga(),
    nextStageSaga(),

    leadStageListSaga(),
    searchPipelineLeadsSaga(),
    searchLeadsByDateSaga(),
    multiFilterLeadListSaga(),
  ]);
}
