import * as actions from "./types";

export function loginUser(param, resolve, reject) {
  return {
    type: actions.LOGIN_ACTION,
    payload: param,
    resolve,
    reject,
  };
}
