import * as actions from "../actions/types";

const initialState = {
    enableDisableAutoLogin: [],
};

const enableDisableAutoLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.AUTO_LOGIN_ENABLE_DISABLE:
            return {
                ...state,
                enableDisableAutoLogin:action.payload,
            };
        case actions.AUTO_LOGIN_ENABLE_DISABLE_SUCCESS:
            return {
                ...state,
                enableDisableAutoLogin: action.data,
            };
        case actions.AUTO_LOGIN_ENABLE_DISABLE_ERROR:
            return {
                ...state,
                enableDisableAutoLogin: action.data,
            };
        default: {
            return state;
        }
    }
};
export default enableDisableAutoLoginReducer;