import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";

function enableDisableAutoLoginApi(param) {
  let urlAutoLogin = `${process.env.REACT_APP_API_BASE_URL}${urls.ENABLE_DISABLE_AUTO_LOGIN}${param.autoLoginId}`;
  return axios.request({
    method: "put",
    url: urlAutoLogin,
    data: param.payload,
  });
}

export function* enableAutoLoginActionEffect(enablDisableAutoLogin) {
  const { payload, paramID, resolve, reject } = enablDisableAutoLogin;
  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(
      enableDisableAutoLoginApi,
      enablDisableAutoLogin
    );
    // yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.AUTO_LOGIN_ENABLE_DISABLE_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
    return yield put({
      type: actions.AUTO_LOGIN_ENABLE_DISABLE_ERROR,
      e,
    });
  }
}

export function* enableAutoLoginActioneWatcher() {
  yield takeLatest(
    actions.AUTO_LOGIN_ENABLE_DISABLE,
    enableAutoLoginActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(enableAutoLoginActioneWatcher)]);
}
