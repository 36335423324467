import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Task - List API
function TaskListApi(params) {
  // const cmpId = getCookie("companyId");
  // console.log(params, "task list saga params logs");
  return axios.request({
    method: "get",
    // url: `${process.env.REACT_APP_API_BASE_URL}${urls.TASK_LIST}/${cmpId}`,
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.TASK_LIST}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// Task-List action effect
export function* taskListActionEffect(taskListAction) {
  const { payload, resolve, reject } = taskListAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(TaskListApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.TASK_LIST_ACTION_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Task-List action watcher
export function* taskListActionWatcher() {
  yield takeLatest(actions.TASK_LIST_ACTION, taskListActionEffect);
}

export default function* rootSaga() {
  yield all([fork(taskListActionWatcher)]);
}
