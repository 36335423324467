import * as actions from "../actions/types";
import * as urls from "../../utils/urls";

import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { getCookie, isEmpty } from "../../utils/common";

import _ from "lodash";
import axios from "../../utils/axios";
import { toggleBackDrop } from "../actions/commonAction";

//dashboard data by date API ?pipeline_id=&createdAt=
function dashboardDataByDateApi(data) {
  // console.log(data);
  let startDate = data.startDate;
  let endDate = data.endDate;
  let id = data.pipelineId;
  // console.log(startDate, endDate);
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.DASHBOARD_DATA_BY_DATE}?startDate=${startDate}&endDate=${endDate}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// dashboard data by date effect
export function* dashboardDataByDateActionEffect(taskDetailsAction) {
  const { payload, resolve, reject } = taskDetailsAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(dashboardDataByDateApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
//Single Task Details action watcher
export function* dashboardDataByDateActionWatcher() {
  yield takeLatest(
    actions.DASHBOARD_DATA_DATE,
    dashboardDataByDateActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(dashboardDataByDateActionWatcher)]);
}
