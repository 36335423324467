import * as actions from "../actions/types";

const initialState = {
  taskListData: [],
};

const tasklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.TASK_LIST_ACTION:
      return {
        ...state,
        taskListData: action.data,
      };
    case actions.TASK_LIST_ACTION_SUCCESS:
      return {
        ...state,
        taskListData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default tasklistReducer;
