import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Add api lead source API
function addLeadSourceApi(params) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.ADD_LEADSOURCE}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: params,
  });
}

// Add new api lead source action effect
export function* addLeadSourceActionEffect(leadSourceAction) {
  const { payload, resolve, reject } = leadSourceAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(addLeadSourceApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}

//add lead source action watcher
export function* addLeadSourceActionWatcher() {
  yield takeLatest(actions.ADD_LEAD_SOURCE, addLeadSourceActionEffect);
}

export default function* rootSaga() {
  yield all([fork(addLeadSourceActionWatcher)]);
}
