import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Lenders fields - List API
function LendersFieldsApi() {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.LENDERSFIELDS}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// lenders-List action effect
export function* lendersFieldsActionEffect(lendersfieldsAction) {
  const { payload, resolve, reject } = lendersfieldsAction;

  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(LendersFieldsApi, payload);
    // yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      // console.log(data);
      if (resolve) resolve(data);
      return yield put({
        type: actions.LENDERS_ACTION_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// lenders-List action watcher
export function* lendersFieldsActionWatcher() {
  yield takeLeading(actions.LENDERS_ACTION, lendersFieldsActionEffect);
}

export default function* rootSaga() {
  yield all([fork(lendersFieldsActionWatcher)]);
}
