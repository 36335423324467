import * as actions from "../actions/types";

const initialState = {
  customerDetailListData: [],
};

const customerDetailListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CUSTOMER_DETAIL_ACTION:
      return {
        ...state,
        customerDetailListData: action.data,
      };
    case actions.CUSTOMER_DETAIL_ACTION_SUCCESS:
      return {
        ...state,
        customerDetailListData: action.data,
      };
      default:
        return state;
    }
};
export default customerDetailListReducer;
