import * as actions from "../actions/types";

const initialState = {
    leadsDashBoardReduce: [],
    lenderDashboardDetails: []
};

const lenderDashBoardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.DYNAMIC_LENDER_DASHBOARD:
            return {
                ...state,
                leadsDashBoardReduce: action.data,
                // allUsers: state.allUsers.map(allUser => allUser.data.id === action.data.data.id)
            };
        case actions.DYNAMIC_LENDER_DASHBOARD_SUCCESS:
            return {
                ...state,
                leadsDashBoardReduce: action.data,
            };
        case actions.DYNAMIC_LENDER_DASHBOARD_ERROR:
            return {
                ...state,
                leadsDashBoardReduce: action.data,
            }
        case action.DASHBOARD_LENDER_DETAILS:
            return {
                ...state,
                lenderDashboardDetails: action.data
            }
        case action.DASHBOARD_LENDER_DETAILS_SUCCESS:
            return {
                ...state,
                lenderDashboardDetails: action.data
            }
        case action.DASHBOARD_LENDER_DETAILS_ERROR:
            return {
                ...state,
                lenderDashboardDetails: action.data
            }
        default: {
            return state;
        }
    }
};
export default lenderDashBoardReducer;
